import React from 'react';

const plansInfo = {
    "Basics": {
        "Dollars Places": "$9.",
        "Cents Places": "99",
        "Cost Description": "per month when paid annually, or $11.99/mo excl. sales tax/VAT",
        "Plan Description": "Just the basics to get the job done.",
        "Get Link": "https://panel.mywikis.com/cart.php?a=add&pid=9",
        "Unlimited Pages": true,
        "Unlimited Users": true,
        "No Ads": true,
        "HTTPS Protection": true,
        "No MyWikis Branding": true,
        "All Privacy Tiers": true,
        "Automatic Free MediaWiki Upgrades": true,
        "Content Licensing Customization": true,
        "Mobile Site Support": true,
        "50+ Extensions Pre-Installed": true,
        "Add Extensions For Free": true,
        "Add Skins For Free": true,
        "Daily Automatic Backups": true,
        "MyWikis Self Service Portal": true,
        "Free Subdomain": true,
        "Bring Your Own Domain": false,
        "Visual Editor": false,
		"Enhanced Search (CirrusSearch)": false,
        "Template Import Services": false,
        "Semantic MediaWiki": false,
        "Matomo Analytics": false,
        "SSO Integration": false,
        "Mass Add Users": false,
        "HTTP Auth/IP allowlist": false,
        "SSH Access": false,
        "Bespoke Extension and Skin Files": false,
		"MediaWiki Version": "Latest LTS",
        "Included Wikis": <span><b>1</b> Wiki</span>,
        "Price Per Additional Wiki": <span>$5.00/mo</span>,
        "File Storage": <span><b>10</b> GB</span>,
        "Best For": <span>Simple personal wikis</span>,
        "Monthly Pricing": <span>$11.99</span>,
        "Annual Pricing": <span>$9.99/mo or $119.99/yr</span>
    },
    "Pro": {
        "Dollars Places": "$14.",
        "Cents Places": "99",
        "Cost Description": "per month when paid annually, or $17.99/mo excl. sales tax/VAT",
        "Plan Description": "Everything needed to make your wiki shine.",
        "Get Link": "https://panel.mywikis.com/cart.php?a=add&pid=10",
        "Unlimited Pages": true,
        "Unlimited Users": true,
        "No Ads": true,
        "HTTPS Protection": true,
        "No MyWikis Branding": true,
        "All Privacy Tiers": true,
        "Automatic Free MediaWiki Upgrades": true,
        "Content Licensing Customization": true,
        "Mobile Site Support": true,
        "50+ Extensions Pre-Installed": true,
        "Add Extensions For Free": true,
        "Add Skins For Free": true,
        "Daily Automatic Backups": true,
        "MyWikis Self Service Portal": true,
        "Free Subdomain": true,
        "Bring Your Own Domain": true,
        "Visual Editor": true,
		"Enhanced Search (CirrusSearch)": true,
        "Template Import Services": true,
        "Semantic MediaWiki": false,
        "Matomo Analytics": false,
        "SSO Integration": false,
        "Mass Add Users": false,
        "HTTP Auth/IP allowlist": false,
        "SSH Access": false,
        "Bespoke Extension and Skin Files": false,
		"MediaWiki Version": "Latest LTS",
        "Included Wikis": <span><b>1</b> Wiki</span>,
        "Price Per Additional Wiki": <span>$5.00/mo</span>,
        "File Storage": <span><b>25</b> GB</span>,
        "Best For": <span>Standard personal wikis</span>,
        "Monthly Pricing": <span>$17.99</span>,
        "Annual Pricing": <span>$14.99/mo or $179.99/yr</span>
    },
    "Quantum": {
        "Dollars Places": "$24.",
        "Cents Places": "99",
        "Cost Description": "per month when paid annually, or $29.99/mo excl. sales tax/VAT",
        "Plan Description": "Empower your wiki with extra oomph.",
        "Get Link": "https://panel.mywikis.com/cart.php?a=add&pid=17",
        "Unlimited Pages": true,
        "Unlimited Users": true,
        "No Ads": true,
        "HTTPS Protection": true,
        "No MyWikis Branding": true,
        "All Privacy Tiers": true,
        "Automatic Free MediaWiki Upgrades": true,
        "Content Licensing Customization": true,
        "Mobile Site Support": true,
        "50+ Extensions Pre-Installed": true,
        "Add Extensions For Free": true,
        "Add Skins For Free": true,
        "Daily Automatic Backups": true,
        "MyWikis Self Service Portal": true,
        "Free Subdomain": true,
        "Bring Your Own Domain": true,
        "Visual Editor": true,
		"Enhanced Search (CirrusSearch)": true,
        "Template Import Services": true,
        "Semantic MediaWiki": true,
        "Matomo Analytics": true,
        "SSO Integration": "Basic SSO",
        "Mass Add Users": true,
        "HTTP Auth/IP allowlist": false,
        "SSH Access": false,
        "Bespoke Extension and Skin Files": false,
		"MediaWiki Version": "Latest LTS",
        "Included Wikis": <span><b>2</b> Wikis</span>,
        "Price Per Additional Wiki": <span>$5.00/mo</span>,
        "File Storage": <span><b>100</b> GB</span>,
        "Best For": <span>Wikis needing premium features</span>,
        "Monthly Pricing": <span>$29.99</span>,
        "Annual Pricing": <span>$24.99/mo or $299.99/yr</span>
    },
    "Ultra": {
        "Dollars Places": "$39.",
        "Cents Places": "99",
        "Cost Description": "per month when paid annually, or $47.99/mo excl. sales tax/VAT",
        "Plan Description": "For the most advanced wikis.",
        "Get Link": "https://panel.mywikis.com/cart.php?a=add&pid=18",
        "Unlimited Pages": true,
        "Unlimited Users": true,
        "No Ads": true,
        "HTTPS Protection": true,
        "No MyWikis Branding": true,
        "All Privacy Tiers": true,
        "Automatic Free MediaWiki Upgrades": true,
        "Content Licensing Customization": true,
        "Mobile Site Support": true,
        "50+ Extensions Pre-Installed": true,
        "Add Extensions For Free": true,
        "Add Skins For Free": true,
        "Daily Automatic Backups": true,
        "MyWikis Self Service Portal": true,
        "Free Subdomain": true,
        "Bring Your Own Domain": true,
        "Visual Editor": true,
		"Enhanced Search (CirrusSearch)": true,
        "Template Import Services": true,
        "Semantic MediaWiki": true,
        "Matomo Analytics": true,
        "SSO Integration": "Advanced & Custom SSO",
        "Mass Add Users": true,
        "HTTP Auth/IP allowlist": true,
        "SSH Access": "On Dedicated only",
        "Bespoke Extension and Skin Files": "On Dedicated only",
		"MediaWiki Version": "Any",
        "Included Wikis": <span><b>3</b> Wikis</span>,
        "Price Per Additional Wiki": <span>$5.00/mo</span>,
        "File Storage": <span><b>1</b> TB or more</span>,
        "Best For": <span>High traffic wikis; Highly customized wikis</span>,
        "Monthly Pricing": <span>$47.99</span>,
        "Annual Pricing": <span>$39.99/mo or $479.99/yr</span>
    },
    "Business Foundation": {
        "Starting at": true,
        "Dollars Places": "$20",
        "Cents Places": "",
        "Cost Description": "per month when paid annually, or $25/mo excl. sales tax/VAT",
        "Plan Description": "Just the basics to get the job done.",
        "Get Link": "https://forms.gle/xtygRHziT8VLDd1F9",
        "Unlimited Pages": true,
        "Unlimited Users": true,
        "No Ads": true,
        "HTTPS Protection": true,
        "No MyWikis Branding": true,
        "All Privacy Tiers": true,
        "Automatic Free MediaWiki Upgrades": true,
        "Content Licensing Customization": true,
        "Mobile Site Support": true,
        "50+ Extensions Pre-Installed": true,
        "Add Extensions For Free": true,
        "Add Skins For Free": true,
        "Daily Automatic Backups": true,
		"Enhanced Search (CirrusSearch)": true,
        "MyWikis Self Service Portal": true,
        "Free Subdomain": true,
        "Bring Your Own Domain": false,
        "Visual Editor": false,
        "Template Import Services": false,
        "Semantic MediaWiki": false,
        "Matomo Analytics": false,
        "SSO Integration": false,
        "Mass Add Users": false,
        "HTTP Auth/IP allowlist": false,
        "SSH Access": false,
        "Bespoke Extension and Skin Files": false,
		"MediaWiki Version": "Latest LTS",
        "Included Wikis": <span><b>1</b> Wiki</span>,
        "Price Per Additional Wiki": <span>10% discount</span>,
        "File Storage": <span><b>25</b> GB</span>,
        "Best For": <span>Simple business collaboration</span>,
        "Monthly Pricing": <span>By number of employees:<br />6-15: $25<br />16-25: $45<br />26-50: $85</span>,
        "Annual Pricing": <span>By number of employees:<br />6-15: $20/mo or $240/yr<br />16-25: $35/mo or $420/yr<br />26-50: $70/mo or $840/yr</span>
    },
    "Business Pro": {
        "Starting at": true,
        "Dollars Places": "$30",
        "Cents Places": "",
        "Cost Description": "per month when paid annually, or $35/mo excl. sales tax/VAT",
        "Plan Description": "Everything needed to make your wiki shine.",
        "Get Link": "https://forms.gle/xtygRHziT8VLDd1F9",
        "Unlimited Pages": true,
        "Unlimited Users": true,
        "No Ads": true,
        "HTTPS Protection": true,
        "No MyWikis Branding": true,
        "All Privacy Tiers": true,
        "Automatic Free MediaWiki Upgrades": true,
        "Content Licensing Customization": true,
        "Mobile Site Support": true,
        "50+ Extensions Pre-Installed": true,
        "Add Extensions For Free": true,
        "Add Skins For Free": true,
        "Daily Automatic Backups": true,
		"Enhanced Search (CirrusSearch)": true,
        "MyWikis Self Service Portal": true,
        "Free Subdomain": true,
        "Bring Your Own Domain": true,
        "Visual Editor": true,
        "Template Import Services": true,
        "Semantic MediaWiki": false,
        "Matomo Analytics": false,
        "SSO Integration": false,
        "Mass Add Users": false,
        "HTTP Auth/IP allowlist": false,
        "SSH Access": false,
        "Bespoke Extension and Skin Files": false,
		"MediaWiki Version": "Latest LTS",
        "Included Wikis": <span><b>1</b> Wiki</span>,
        "Price Per Additional Wiki": <span>20% discount</span>,
        "File Storage": <span><b>50</b> GB</span>,
        "Best For": <span>Standard business collaboration</span>,
        "Monthly Pricing": <span>By number of employees:<br />6-15: $35<br />16-25: $85<br />26-50: $170</span>,
        "Annual Pricing": <span>By number of employees:<br />6-15: $30/mo or $360/yr<br />16-25: $75/mo or $900/yr<br />26-50: $150/mo or $1,800/yr</span>
    },
    "Business Quantum": {
        "Starting at": true,
        "Dollars Places": "$50",
        "Cents Places": "",
        "Cost Description": "per month when paid annually, or $55/mo excl. sales tax/VAT",
        "Plan Description": "Empower your wiki with extra oomph.",
        "Get Link": "https://forms.gle/xtygRHziT8VLDd1F9",
        "Unlimited Pages": true,
        "Unlimited Users": true,
        "No Ads": true,
        "HTTPS Protection": true,
        "No MyWikis Branding": true,
        "All Privacy Tiers": true,
        "Automatic Free MediaWiki Upgrades": true,
        "Content Licensing Customization": true,
        "Mobile Site Support": true,
        "50+ Extensions Pre-Installed": true,
        "Add Extensions For Free": true,
        "Add Skins For Free": true,
        "Daily Automatic Backups": true,
		"Enhanced Search (CirrusSearch)": true,
        "MyWikis Self Service Portal": true,
        "Free Subdomain": true,
        "Bring Your Own Domain": true,
        "Visual Editor": true,
        "Template Import Services": true,
        "Semantic MediaWiki": true,
        "Matomo Analytics": true,
        "SSO Integration": "Basic SSO",
        "Mass Add Users": true,
        "HTTP Auth/IP allowlist": true,
        "SSH Access": false,
        "Bespoke Extension and Skin Files": false,
		"MediaWiki Version": "Latest LTS",
        "Included Wikis": <span><b>1</b> Wiki</span>,
        "Price Per Additional Wiki": <span>30% discount</span>,
        "File Storage": <span><b>200</b> GB</span>,
        "Best For": <span>Empowering dynamic business wikis</span>,
        "Monthly Pricing": <span>By number of employees:<br />6-15: $55<br />16-25: $140<br />26-50: $300</span>,
        "Annual Pricing": <span>By number of employees:<br />6-15: $50/mo or $600/yr<br />16-25: $125/mo or $1,500/yr<br />26-50: $250/mo or $3,000/yr</span>
    },
    "Enterprise": {
        "Starting at": true,
        "Dollars Places": "Contact us",
        "Cents Places": "",
        "Cost Description": "for a customized quote for your needs.",
        "Plan Description": "Custom-tailored for you.",
        "Get Link": "https://forms.gle/gyWQkiVr39e7Qm8p9",
        "Unlimited Pages": true,
        "Unlimited Users": true,
        "No Ads": true,
        "HTTPS Protection": true,
        "No MyWikis Branding": true,
        "All Privacy Tiers": true,
        "Automatic Free MediaWiki Upgrades": true,
        "Content Licensing Customization": true,
        "Mobile Site Support": true,
        "50+ Extensions Pre-Installed": true,
        "Add Extensions For Free": true,
        "Add Skins For Free": true,
        "Daily Automatic Backups": true,
		"Enhanced Search (CirrusSearch)": true,
        "MyWikis Self Service Portal": true,
        "Free Subdomain": true,
        "Bring Your Own Domain": true,
        "Visual Editor": true,
        "Template Import Services": true,
        "Semantic MediaWiki": true,
        "Matomo Analytics": true,
        "SSO Integration": "Advanced or Custom SSO",
        "Mass Add Users": true,
        "HTTP Auth/IP allowlist": true,
        "SSH Access": true,
        "Bespoke Extension and Skin Files": true,
		"MediaWiki Version": "Any version",
        "Included Wikis": <span>As many as you need</span>,
        "Price Per Additional Wiki": <span>Contact us</span>,
        "File Storage": <span>As much as you need</span>,
        "Best For": <span>Wikis for large companies and enterprises</span>,
        "Monthly Pricing": <span>Contact us</span>,
        "Annual Pricing": <span>Contact us</span>
    },
}

export default plansInfo
