import lpediaLogo from "../images/lpedia-logo.png"
import arduinoInfoLogo from "../images/arduino-info-logo.png"
import abnormalPsychLogo from "../images/abnormal-psych-logo.png"
import twitLogo from "../images/twit-logo.png"
import kustomramaLogo from "../images/kustomrama-logo.jpeg"

const showcaseInfo = {
    "LPedia": {
        "description": "The official Libertarian Party wiki",
        "logo": lpediaLogo,
        "link": "https://lpedia.org/wiki/Main_Page"
    },
    "Arduino Info": {
        "description": "Learning all about arduinos",
        "logo": arduinoInfoLogo,
        "link": "https://arduinoinfo.mywikis.net/wiki/HOME"
    },
    "Abnormal Psychology": {
        "description": "Documenting mental disorders",
        "logo": abnormalPsychLogo,
        "link": "https://abpsych.mywikis.wiki/wiki/Main_Page"

    },
    "Official TWiT": {
        "description": "This Week in Tech",
        "logo": twitLogo,
        "link": "https://wiki.twit.tv/wiki/Main_Page"

    },
    "Kustomrama": {
        "description": "Custom cars wiki",
        "logo": kustomramaLogo,
        "link": "https://kustomrama.com/wiki/Main_Page"
    },
}

export default showcaseInfo