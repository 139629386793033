import React from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet/es/Helmet'
import './Suspended.css'
import default404Icon from "../../images/default-404.svg"

const Suspended = () => {
    return (
        <div>
            <Helmet>
				<title>Wiki Suspended | MyWikis | Personalized wiki hosting</title>
			</Helmet>
            <div className="gradient-section-light heading-primary-light">
				Wiki Unavailable
			</div>
			<div className="default-404">
				<img className="default-404-icon" src={default404Icon} alt="404-icon" />
				<div className="heading-primary-dark">
					Oops! This wiki is currently unavailable.
				</div>{" "}
				<br />
				<p>
					You may have arrived here while trying to access a MyWikis wiki that
					has an expired subscription or is quarantined due to spam. If you need
					help restoring your wiki, please{" "}
					<Link to="/contact" className="button-link-blue">
						contact support
					</Link>
					.<br />
					<br /> For more information on restoring a wiki suspended due to
					missed payments,{" "}
					<a
						href="https://panel.mywikis.com/index.php?rp=/knowledgebase/31/Restoring-an-unpaid-wiki-subscription.html"
						className="button-link-blue"
					>
						please see this support article
					</a>
					.
				</p>
				<br />
				<Link to="/" className="button-tertiary">
					Return to Homepage
				</Link>
			</div>
        </div>
    )
}

export default Suspended