import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./DMCA.css"

const DMCA = () => {
	return (
		<div>
			<Helmet>
				<title>DMCA Compliance | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">
				DMCA Compliance
			</div>
			<div className="dmca body-text-dark">
				<p>
					MyWikis complies with the Digital Millennium Copyright Act of 1998.
				</p>
				<p>
					If, by the definition of U.S. law, you are the rightful owner of
					copyrighted material that is improperly used on mywikis.com,
					mywikis.net, or a MyWikis operated wiki, please{" "}
					<Link to="/contact" className="button-link">
						contact our support
					</Link>{" "}
					team to provide the URL of the offending content and the additional
					information required by the Act, as{" "}
					<Link to="/terms" className="button-link">
						detailed on our Terms of Service
					</Link>
					.
				</p>
			</div>
		</div>
	)
}

export default DMCA
