import "./BannerAlert.css"

const BannerAlert = ({ displayBanner, setDisplayBanner, bannerTitle, bannerContent, bannerButton }) => {
    return (
        <div class={
            "banner-alert"
            + (displayBanner ? " banner-on" : " banner-off")
        }>
            <span
                class="banner-close-button"
                onClick={() => setDisplayBanner(false)}
            >
                ×
            </span>
            <div class="banner-content">
                <h2>{bannerTitle ?? 'Hello!'}</h2>
                <p>{bannerContent ?? 'Welcome to MyWikis'}</p>
                {bannerButton}
            </div>
        </div>
    )
}

export default BannerAlert