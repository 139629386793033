import React from "react"
import Helmet from "react-helmet/es/Helmet"
import "./UseCases.css"
import companyWikiIcon from "../../images/company-wiki-icon.svg"
import classroomsIcon from "../../images/classrooms-icon.svg"
import encyclopediasIcon from "../../images/encyclopedias-icon.svg"
import personalUseIcon from "../../images/personal-use-icon.svg"
import versatileUseCasesIcon from "../../images/versatile-usecases-icon.svg"
import CallToAction from "../../components/CallToAction/CallToAction"
import CompareHostsTable from "../../components/CompareHostsTable/CompareHostsTable"

const UseCases = () => {
	return (
		<div>
			<Helmet>
				<title>Use cases | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">Use Cases</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">
					Wondering why you should use a wiki?
				</div>
			</div>
			<div className="usecases-icons-section">
				<div className="heading-secondary-dark usecases-icons-heading">
					A wiki is versatile and can be adapted to be the ideal website and
					collaboration tool for many scenarios.{" "}
				</div>
				<div className="usecases-icons-container">
					<div className="icon-container top-icon-row">
						<img src={companyWikiIcon} alt="company-wiki" />
						<p className="subheading-primary-dark company-wiki-icon">
							Company Wiki
						</p>
					</div>
					<div className="icon-container top-icon-row">
						<img src={encyclopediasIcon} alt="support knowledge base" />
						<p className="subheading-primary-dark support-knowledgebase-icon">
							Support Knowledgebase
						</p>
					</div>
					<div className="icon-container middle-icon-row">
						<img src={classroomsIcon} alt="classrooms" />
						<p className="subheading-primary-dark classrooms-icon">
							Class Wiki
						</p>
					</div>
					<div className="icon-container middle-versatile">
						<img
							className="versatile-usecases-icon"
							src={versatileUseCasesIcon}
							alt="versatile-usecases"
						/>
						<p className="subheading-primary-dark classrooms-icon"></p>
					</div>
					<div className="icon-container middle-icon-row">
						<img src={personalUseIcon} alt="personal use" />
						<p className="subheading-primary-dark personal-use-icon">
							Personal Wiki
						</p>
					</div>
					<div className="icon-container bottom-icon-row">
						<img src={encyclopediasIcon} alt="encyclopedias" />
						<p className="subheading-primary-dark encyclopedias-icon">
							Encyclopedia
						</p>
					</div>
				</div>
			</div>
			<div className="features body-text-light">
				<div className="feature-box-left usecase-1">
					<div className="usecases-box-text">
						<h2>Wiki as a website</h2>
						<p>
							A wiki is a website. You can add text, images, videos, and even
							widgets to each page, and pages link to each other. The best part
							about a wiki is that it's not hard to edit. On some wikis, anybody
							can edit any page. On other wikis, authorized admins can edit the
							pages, while viewers can see the changes made to the pages
							immediately as they are updated in real time.
						</p>
					</div>
				</div>
				<div className="feature-box-right usecase-2">
					<div className="usecases-box-text">
						<h2>Wiki as a collaboration tool</h2>
						<p>
							In general, users can edit any page on a wiki. This makes a wiki
							the perfect place to collaborate and share information. The only
							way to share information properly is if everyone can report back
							at the same place, add their information, and everyone else can
							see the updated information. Blogs and other websites don't let
							just anyone edit the pages. Furthermore, they might require use of
							HTML, CSS, and JavaScript, making it difficult for the average
							person to update the website.{" "}
							<b>
								This results in a loss of collaboration due to a high learning
								curve causing frustration and friction.
							</b>{" "}
							<br />
							<br />
							However, wikis eliminate this problem entirely. MyWikis offers a
							visual editor that allows anyone, even people completely new to
							wikis, to edit the pages using an easy-to-use interface offering
							similar features to Microsoft Word and Google Docs. Sharing
							documents on a shared drive and editing them is difficult to keep
							track of, difficult to organize, difficult to categorize, and most
							importantly, difficult to search for the right information. When
							you put your information on a wiki, you're making all of the
							information easily searchable using the wiki's powerful search
							engine.
						</p>
					</div>
				</div>
			</div>
			<div className="host-own-wiki">
				<div className="heading-secondary-dark center-text">
					Then why don't I just host my own wiki?
				</div>
				<br />
				<p className="body-text-dark">
					You certainly can!{" "}
					<b>
						However, getting a wiki up and running is not as easy as it sounds.
					</b>
					<br />
					<br />
					MediaWiki is an extremely powerful wiki engine (after all, it powers
					Wikipedia!), and thus can be difficult to set up. While the MediaWiki
					set up process is not too difficult, the addition of extensions
					necessary to make the wiki functional can be particularly challenging
					and time consuming. In particular, addition of features necessary for
					many companies, such as single sign on (SSO), data privacy guarantees,
					and visual editing, are the most difficult to configure correctly.
					Running a MediaWiki instance properly requires lots of experience.
					Rather than investing weeks, if not months, on perfecting your wiki
					setup, why not cut it down to a matter of minutes? At MyWikis, we not
					only create your wiki immediately after sign-up, we also bundle all of
					the essential extensions with your wiki. We also provide
					recommendations on how you can improve your wiki, achievable through
					one-click installs of additional extensions.
				</p>
			</div>
			<CompareHostsTable />
			<div className="companies-and-personal-container">
				<div className="companies-and-personal">
					<div className="companies-and-personal-half">
						<div className="heading-secondary-dark center-text">
							MyWikis for Companies
						</div>
						<div className="companies-and-personal-box">
							<div className="heading-tertiary">Company Wiki</div>
							<p className="body-text-dark">
								The best collaboration tool for employees is a wiki. Companies
								should use wikis to eliminate siloing of divisions, encourage
								cross-division collaboration. Keep track of progress where
								everyone can see it, not just the team or the individual.
								Eliminate accidental work duplication by centralizing everything
								in one place. An easy to use interface means anyone can edit a
								wiki intuitively. Use single sign on (SSO) to unify accounts.
							</p>
						</div>
						<div className="companies-and-personal-box">
							<div className="heading-tertiary">Support Knowledgebase</div>
							<p className="body-text-dark">
								Easily organize your frequently asked questions and
								knowledgebase information on a wiki. Offer powerful search to
								your customers so they can find the right help for their needs.
								Make it easy for your content writers to add and update
								information in real time with visual editor and easy to use edit
								capabilities across the entire wiki. Add supporting multimedia
								with ease into each help page.
							</p>
						</div>
						<a
							href="/for-companies"
							className="button-tertiary companies-and-personal-btn"
						>
							MyWikis for Companies
						</a>
					</div>
					<div className="companies-and-personal-half">
						<div className="heading-secondary-dark center-text">
							MyWikis for Personal Use
						</div>
						<div className="companies-and-personal-box">
							<div className="heading-tertiary">Schools and Classrooms</div>
							<p className="body-text-dark">
								Create a more immersive online environment for your class. Make
								remote learning or a flipped classroom become a reality with an
								easy-to-use place to put all your curriculum and teaching
								content. Let students have a place to collaborate, work on
								projects, and do their coursework. Manage your students with
								ease. Connect with your school's single sign on platform.
							</p>
						</div>
						<div className="companies-and-personal-box">
							<div className="heading-tertiary">Personal Use</div>
							<p className="body-text-dark">
								Got a hobby? Want to write a diary or journal? Jot down your
								thoughts, organize them, split them into pages, and search
								through them. A wiki is the perfect organizational tool for your
								thoughts and information, even if it's just for yourself!
							</p>
						</div>
						<a
							href="/for-personal"
							className="button-tertiary companies-and-personal-btn"
						>
							MyWikis for Personal Use
						</a>
					</div>
				</div>
				<div className="information-encyclopedia-container">
					<div className="heading-tertiary center-text">
						Information Encyclopedia
					</div>
					<p className="body-text-dark">
						A Wikipedia, except for a specific topic. Or maybe even a
						Wikipedia+. The possibilities are endless with a MyWikis wiki. Best
						of all, you're running on the same software that powers Wikipedia.
					</p>
					<p className="body-text-dark">
						The{" "}
						<a
							href="https://abpsych.mywikis.wiki/wiki/Main_Page"
							className="button-link"
						>
							Abnormal Psychology Wiki
						</a>{" "}
						documents clinical mental conditions, their symptoms, DSM-5
						diagnosis requirements, and treatment/prognosis.
					</p>
					<p className="body-text-dark">
						The Libertarian Party of the United States, the third-largest
						political party in the U.S., trusts MyWikis to be the home of its
						wiki to preserve their history and ideology:{" "}
						<a href="https://lpedia.org/wiki/Main_Page" className="button-link">
							LPedia
						</a>
						. MyWikis believes in protecting peaceful freedom of speech, and
						does not endorse any particular political belief.
					</p>
				</div>
			</div>
			<div className="how-can-we-help">
				<div className="heading-secondary-light">
					See how MyWikis can help you.
				</div>
				<a
					href="/for-personal"
					className="button-quaternary how-can-we-help-btn"
				>
					MyWikis for Personal Use
				</a>
				<a
					href="/for-companies"
					className="button-quaternary how-can-we-help-btn"
				>
					MyWikis for Companies
				</a>
			</div>
			<CallToAction />
		</div>
	)
}

export default UseCases
