import React, { useState } from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./Login.css"

const Login = () => {
	const [wikiID, setWikiID] = useState("")

	const handleLoginWiki = (e) => {
		e.preventDefault()
		if (wikiID === "" || wikiID === undefined || wikiID === null) return
		window.location.href = `https://${wikiID}.mywikis.wiki/wiki/Special:UserLogin`
	}

	return (
		<div className="login-container">
			<Helmet>
				<title>Log in | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">Login</div>
			<div className="login">
				<div className="login-half">
					<div className="login-box-1">
						<div className="heading-secondary-dark">Log in to your wiki</div>
						<form onSubmit={handleLoginWiki}>
							<label>Enter your Wiki ID</label>
							<input
								className="loginwiki-input"
								type="text"
								onChange={(e) => setWikiID(e.target.value)}
							/>
							<p className="login-subtext">
								For instance, if your wiki's URL is https://awesome.mywikis.wiki,
								then your Wiki ID would be "awesome".
							</p>
							<button className="button-primary loginwiki-btn" type="submit">
								Login
							</button>
						</form>
						<p>
							Don't have a wiki yet?{" "}
							<Link to="/createawiki" className="button-link-blue">
								Create one now!
							</Link>
						</p>
					</div>
				</div>
				<div className="login-half">
					<div className="login-box-2">
						<div className="heading-secondary-dark">
							Log in to the MyWikis{" "}
							<span className="color-quaternary">Client Panel</span>
						</div>
						<p className="login-subtext">
							The MyWikis Client Panel is the one-stop shop for wiki owners.
							Receive customer support, find answers to frequently asked
							questions, and handle billing all in one place.
						</p>
						<a
							className="button-green"
							href="https://panel.mywikis.com/clientarea.php"
						>
							Log in to MyWikis Client Panel
						</a>
					</div>
					<div className="login-box-2">
						<div className="heading-secondary-dark">
							Log in to MyWikis{" "}
							<span className="color-quaternary">Self Service</span>
						</div>
						<p className="login-subtext">
							Manage your existing wikis with MyWikis Self Service.
						</p>
						<a
							className="button-green"
							href="https://selfservice.mywikis.com/"
						>
							Log in to MyWikis Self Service
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
