/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"
import Select from "react-select"
import { useLocation } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./PriceCalculator.css"
import calculatePrice from "../../utils/calculateprice"
import { countriesMap } from "../../utils/calculateprice"
import getGeolocationInfo from "../../utils/geolocation"

const { countryCodeEmoji } = require('country-code-emoji')

const productOptions = [
	// { value: "invalid", label: "Select a product" },
	{ value: "basics", label: "MyWikis Basics" },
	{ value: "pro", label: "MyWikis Pro" },
	{ value: "quantum", label: "MyWikis Quantum" },
	{ value: "ultra", label: "MyWikis Ultra" },
]

const countryCodes = [
	"AT",
	"BE",
	"BG",
	"CA",
	"CY",
	"CZ",
	"DE",
	"DK",
	"EE",
	"ES",
	"FI",
	"FR",
	"GB",
	"GR",
	"HR",
	"HU",
	"IE",
	"IT",
	"LT",
	"LU",
	"LV",
	"MT",
	"NL",
	"PL",
	"PT",
	"RO",
	"SE",
	"SI",
	"SK",
	"US",
	"TX",
	"other",
]

const PriceCalculator = () => {
	const { search } = useLocation()
	const searchParams = new URLSearchParams(search)
	const product = searchParams.get("product")
	const addons = searchParams.getAll("addons[]")
	const extrawikis = searchParams.get("extrawikis")
	const extrastorage = searchParams.get("extrastorage")
	const country = searchParams.get("country")

	const [locationInfo, setLocationInfo] = useState({})

	useEffect(() => {
		getGeolocationInfo(setLocationInfo)
	}, [])
	
	const renderContent = () => {
		if (
			product === null ||
			country === null ||
			extrawikis === null ||
			extrastorage === null ||
			addons === null
		) {
			return (
				<>
					<p>
						Our after-tax prices will differ, depending on the country in which
						you are located and the currency with which you are paying.
					</p>
					<p>To view your local pricing, use the following wizard:</p>
					<form method="get" action="/pricecalculator">
						<div className="form-group">
							<label className="heading-tertiary label-heading" for="product">
								<b>Product</b>
							</label>
							<Select
								autoSize={true}
								name="product"
								id="product"
								defaultValue={{ value: "invalid", label: "Select a product" }}
								options={productOptions}
							/>
							<label className="heading-tertiary label-heading">
								<b>Addons</b>
							</label>
							<div className="checkbox-container">
								<label>
									<input className="checkbox" type="checkbox" name="addons[]" value="backupsplus" />
									Backups Plus
								</label>
							</div>
							<div className="checkbox-container">
								<label>
									<input className="checkbox"
										type="checkbox"
										name="addons[]"
										value="prioritysupport"
									/>
									Priority Support
								</label>
							</div>
							<label className="heading-tertiary label-heading">
								<b>Extras</b>
							</label>
							<div className="form-group">
								<label className="extras-label" for="extrawikis">
									Extra Wikis
								</label>
								<input
									className="extras-input"
									type="number"
									id="extrawikis"
									name="extrawikis"
									defaultValue="0"
									min="0"
								/>
							</div>
							<div className="form-group">
								<label className="extras-label" for="extrastorage">
									Extra Storage (GB)
								</label>
								<input
									className="extras-input"
									type="number"
									id="extrastorage"
									name="extrastorage"
									defaultValue="0"
									min="0"
								/>
							</div>
							<label className="heading-tertiary label-heading" for="country">
								Country
							</label>
							<Select
								name="country"
								id="country"
								defaultValue={
									{
										value: locationInfo.region_code === "TX"
											? "TX"
											: locationInfo.country_code || "invalid",
										label: (
												locationInfo.region_code === "TX"
												? countryCodeEmoji('US') + " "
												: countriesMap[locationInfo.country_code] !== undefined
												? countryCodeEmoji(locationInfo.country_code) + " " + countriesMap[locationInfo.country_code]
												: "Select a country"
											)
									}
								}
								options={countryCodes.map((countryCode, index) => ({
									value: countryCode,
									label: (
										countryCode === "TX"
										? countryCodeEmoji('US') + " "
										: countryCode.length === 2
										? countryCodeEmoji(countryCode) + " "
										: "")
										+ countriesMap[countryCode],
								}))}
							/>
							{/* <select
								name="country"
								id="country"
								className="form-control select2"
							>
								<option value="invalid">Select a country</option>
								<option value="other">
									I live in a country that's not in this list
								</option>
								{countryCodes.map((countryCode, index) => (
									<option value={countryCode}>
										{countriesMap[countryCode]}
									</option>
								))}
							</select> */}
							<button type="submit" className="button-primary calculate-btn">
								Calculate
							</button>
						</div>
					</form>
				</>
			)
		}

		if (product === "invalid" || country === "invalid") {
			return (
				<>
					<p>Sorry, please go back and select a valid product or country.</p>
					<p>
						<a
							className="button-tertiary go-back-btn"
							href="javascript:window.history.go(-1)"
						>
							Go back
						</a>
					</p>
				</>
			)
		}
		const response = calculatePrice(product, country, {
			backupsplus: addons.includes("backupsplus"),
			prioritysupport: addons.includes("prioritysupport"),
			extrawikis: extrawikis,
			extrastorage: extrastorage,
		})
		return (
			<div className="product-results">
				<div>
					<p>
						Your product: <b>{response["product_name"]}</b>
					</p>
					<p>
						Addons: <b>{response["addons"].toString().split(",").join(", ")}</b>
					</p>
					<p>
						Currency:{" "}
						<b>
							{response["currency"]} {response["currency_symbol"]}
						</b>
					</p>
					<p>
						in{" "}
						<b>
							{
								response["country_code"] === "TX"
								? countryCodeEmoji('US')
								: response["country_code"].length === 2
								? countryCodeEmoji(response["country_code"])
								: ""
							} {response["country_name"]}
						</b>
					</p>
				</div>
				<div className="price-calculator-boxes">
					<div className="price-calculator-box">
						<b>Before tax</b>
						<p className="price-calculator-money">
							{response["currency_symbol"]}
							{response["price_before_tax"]}/mo
						</p>
						<p>or</p>
						<p className="price-calculator-money">
							{response["currency_symbol"]}
							{response["annual_price_before_tax"]}/yr
						</p>
						{response["currency"] === "EUR" && (
							<p>
								(For EU businesses with a VAT number.)
							</p>
						)}
					</div>
					<div className="price-calculator-box">
						<b>After tax</b>
						<p className="price-calculator-money">
							{response["currency_symbol"]}
							{response["price_after_tax"]}/mo
						</p>
						<p>or</p>
						<p className="price-calculator-money">
							{response["currency_symbol"]}
							{response["annual_price_after_tax"]}/yr
						</p>
						<p>
							At {
								response["country_code"] === "TX"
								? countryCodeEmoji('US')
								: response["country_code"].length === 2
								? countryCodeEmoji(response["country_code"])
								: ""
							} {response["country_name"]}'s tax rate of{" "}
							<b>{response["tax_rate"]}</b>%
						</p>
						{response["currency"] === "EUR" && (
							<p>
								(For EU individuals or entities without a VAT number.)
							</p>
						)}
					</div>
				</div>
				<a
					className="button-tertiary go-back-btn"
					href="javascript:window.history.go(-1)"
				>
					Go back
				</a>
			</div>
		)
	}

	return (
		<div>
			<Helmet>
				<title>Price Calculator | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">
				Price Calculator
			</div>
			<div className="price-calculator body-text-dark">
				{renderContent()}
				<p>
					For more information about how MyWikis charges tax,{" "}
					<a
						href="https://panel.mywikis.com/index.php?rp=/knowledgebase/1"
						target="_blank"
						rel="noreferrer"
						className="button-link-blue"
					>
						visit our tax info page
					</a>
					. Disclaimer: The prices listed on this page are for convenience only.
					In case of price discrepancies between your invoice and this page, the
					invoice price prevails.
				</p>
			</div>
		</div>
	)
}

export default PriceCalculator
