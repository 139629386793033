import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./HelpMeChoose.css"

const HelpMeChoose = () => {
	return (
		<div>
			<Helmet>
				<title>
					Help me choose a plan | MyWikis | Personalized wiki hosting
				</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">
				Which plan is best for me?
			</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">
					Answer a few questions below and we'll give you our recommendation on
					what plan's best.{" "}
				</div>
				<p className="body-text-light">
					Want to compare all features by yourself? Visit the{" "}
					<Link className="button-link-white" to="/plans">
						plans
					</Link>{" "}
					page.
				</p>
			</div>
			<div className="help-me-choose center-text">
				{/* <p>
					Answer a few questions below and we'll give you our recommendation on
					what plan's best.
				</p>
				<p>
					Want to compare all features by yourself? Visit the{" "}
					<a className="button-link" href="plans">plans</Link> page.
				</p> */}
				<iframe
					title="help-me-choose"
					src="https://docs.google.com/forms/d/e/1FAIpQLSff0J7zsZ8K1eZHxHacxUeo4XiloYWLN-esAjkCLheKTxnCwA/viewform?embedded=true"
					width="100%"
					height="600"
					frameborder="0"
				>
					Loading… (Not loading?{" "}
					<a href="https://forms.gle/zkXyGF6P5L5dirV17">
						Click here for the questionnaire
					</a>
					.)
				</iframe>
			</div>
		</div>
	)
}

export default HelpMeChoose
