import React from "react"
import "./Addons.css"
import addonsInfo from "../../utils/addonsinfo"

const Addons = () => {
	const addonsList = [
		"Backups Plus",
		"Elite Support",
		"Extra Wikis",
		"Extra Storage",
	]

	return (
		<div className="addons-container">
			{addonsList.map((addon, index) => (
				<div key={index} className="addon-box">
					<div className="addon-bar" style={{backgroundColor: addonsInfo[addon]["bar color"]}}>&#8203;</div>
					<h2 className="heading-secondary">{addon}</h2>
					<p className="addon-short-description">{addonsInfo[addon]["addon short description"]}</p>
					<p className="addon-main-description">{addonsInfo[addon]["addon main description"]}</p>
					<div className="addon-cost">{addonsInfo[addon]["cost"]}</div>
					<div className="addon-cost-frequency">{addonsInfo[addon]["cost frequency"]}</div>
					<div className="addon-tax-description">{addonsInfo[addon]["tax description"]}</div>

				</div>
			))}
		</div>
	)
}

export default Addons
