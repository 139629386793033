import React from "react"
import Helmet from "react-helmet/es/Helmet"
import "./Showcase.css"
import showcaseInfo from "../../utils/showcaseinfo"

const Showcase = () => {
	const showcaseWikis = [
		"LPedia",
		"Arduino Info",
		"Abnormal Psychology",
		"Official TWiT",
		"Kustomrama",
	]

	return (
		<div className="showcase-container">
			<Helmet>
				<title>Showcase | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">Showcase</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">
					Explore our featured wikis!
				</div>
			</div>
			<div className="showcase">
				{showcaseWikis.map((wiki, index) => (
					<div className="showcasewiki-box">
						<div className="heading-secondary-dark">{wiki}</div>
						<img
							src={showcaseInfo[wiki]["logo"]}
							alt={wiki}
							className="showcasewiki-logo"
						/>
						<p>{showcaseInfo[wiki]["description"]}</p>
						<a href={showcaseInfo[wiki]["link"]} className="button-link-blue">
							check it out!
						</a>
						{" >"}
					</div>
				))}
			</div>
		</div>
	)
}

export default Showcase
