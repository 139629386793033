const { countryCodeEmoji } = require('country-code-emoji')

const GeolocationSnippet = ({ locationInfo }) => {
    let regionNames = new Intl.DisplayNames(['en'], {type: 'region'})

    return (
        <div>
            {locationInfo.country_code &&
                <p>
                    You are accessing MyWikis.com from {locationInfo.country_name && countryCodeEmoji(locationInfo.country_code) + ' '}{locationInfo.country_name ? regionNames.of(locationInfo.country_code) : 'some place'}.
                </p>
            }
            <p>
                {locationInfo.in_eu &&
                    <div>
                        <p>Visit our 🇪🇺 European Union website at <a href="https://www.mywikis.eu">MyWikis.eu</a></p>
                    </div>
                }
            </p>
        </div>
    )
}

export default GeolocationSnippet