import React from "react"
import Helmet from "react-helmet/es/Helmet"
import "./Terms.css"

const Terms = () => {
	return (
		<div>
			<Helmet>
				<title>Terms of Service | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">Terms</div>
			<div className="terms-container">
				<h1>MyWikis Terms of Service</h1>
				<p>Last updated: August 17, 2024</p>

				<p>
					These Terms of Service constitute a legally binding agreement made
					between you, whether personally or on behalf of an entity (“you”) and
					MyWikis LLC (“we,” “us” or “our”), concerning your access to and use
					of the MyWikis website (including, but not limited to, mywikis.com,
					mywikis.org, mywikis.net, mywikis.wiki, the wikis hosted on them, or
					any other wiki/property hosted by MyWikis) as well as any other media
					form, media channel, mobile website or mobile application related,
					linked, or otherwise connected thereto (collectively, the “Site”).
				</p>

				<p>
					MyWikis LLC is a registered Texas limited liability company based in
					Texas, United States of America. We operate internationally with
					respect to the laws of the State of Texas and the United States of
					America. These Terms shall be governed by the laws of Texas without
					regard to Texas's conflicts of law rules.
				</p>

				<p>
					You agree that by accessing the Site, you have read, understood, and
					agree to be bound by all of these Terms of Service. If you do not
					agree with all of these Terms of Service, then you are expressly
					prohibited from using the Site and you must discontinue use
					immediately.
				</p>

				<p>
					Supplemental Terms of Service or documents that may be posted on the
					Site from time to time are hereby expressly incorporated herein by
					reference. We reserve the right, in our sole discretion, to make
					changes or modifications to these Terms of Service at any time and for
					any reason.
				</p>

				<p>
					We may or may not alert you about any changes by updating the “Last
					updated” date of these Terms of Service, and you waive any right to
					receive specific notice of each such change.
				</p>

				<p>
					It is your responsibility to periodically review these Terms of
					Service to stay informed of updates. You will be subject to, and will
					be deemed to have been made aware of and to have accepted, the changes
					in any revised Terms of Service by your continued use of the Site
					after the date such revised Terms of Service are posted.
				</p>

				<p>
					The information provided on the Site is not intended for distribution
					to or use by any person or entity in any jurisdiction or country where
					such distribution or use would be contrary to law or regulation or
					which would subject us to any registration requirement within such
					jurisdiction or country.
				</p>

				<p>
					Accordingly, those persons who choose to access the Site from other
					locations do so on their own initiative and are solely responsible for
					compliance with local laws, if and to the extent local laws are
					applicable.
				</p>

				<p>
					Additionally, for entities who are responsible for the creation,
					maintenance, and payment of a wiki hosted on our Site (hereon referred
					to as "Wiki Owner"), you are additionally responsible for the actions
					of your users on your wiki, and these terms dictate your
					responsibility.
				</p>

				<p>
					The Site is intended for users who are at least 13 years of age. All
					users who are minors in the jurisdiction in which they reside
					(generally under the age of 18) must have the permission of, and be
					directly supervised by, their parent or guardian to use the Site. If
					you are a minor, you must have your parent or guardian read and agree
					to these Terms of Service prior to you using the Site.
				</p>

				<h2>Section 1. Intellectual Property Rights</h2>

				<p>
					Unless otherwise indicated, the Site is our proprietary property and
					all source code, databases, functionality, software, website designs,
					audio, video, text, photographs, and graphics on the Site
					(collectively, the “Content”) and the trademarks, service marks, and
					logos contained therein (the “Marks”) are owned or controlled by us or
					licensed to us, and are protected by copyright and trademark laws and
					various other intellectual property rights and unfair competition laws
					of the United States, foreign jurisdictions, and international
					conventions.
				</p>

				<p>
					The Content and the Marks are provided on the Site “AS IS” for your
					information and personal use only. Except as expressly provided in
					these Terms of Service, no part of the Site and no Content or Marks
					may be copied, reproduced, aggregated, republished, uploaded, posted,
					publicly displayed, encoded, translated, transmitted, distributed,
					sold, licensed, or otherwise exploited for any commercial purpose
					whatsoever, without our express prior written permission.
				</p>

				<p>
					Provided that you are eligible to use the Site, you are granted a
					limited license to access and use the Site and to download or print a
					copy of any portion of the Content to which you have properly gained
					access solely for your personal, non-commercial use. We reserve all
					rights not expressly granted to you in and to the Site, the Content
					and the Marks.
				</p>

				<h2>Section 2. User Registration and Representations</h2>

				<p>
					You may be required to register with the Site, potentially on multiple
					systems. In each case, you agree to keep your password confidential
					and will be responsible for all use of your account and password. We
					reserve the right to remove, reclaim, or change a username you select
					if we determine, in our sole discretion, that such username is
					inappropriate, obscene, or otherwise objectionable.
				</p>

				<p>
					Wiki Owners are also treated as users for the purposes of this
					section, except that Wiki Owners and their designated representatives
					("administrators/sysops" and "bureaucrats" and potentially other
					designations) are given autonomy to manage the users of their own wiki
					further than what is stated on these terms. However, we reserve the
					right to make ultimate decisions concerning users.
				</p>

				<p>By using the Site, you represent and warrant that:</p>

				<p>
					(1) all registration information you submit will be true, accurate,
					current, and complete;
				</p>
				<p>
					(2) you will maintain the accuracy of such information and promptly
					update such registration information as necessary;
				</p>
				<p>
					(3) you have the legal capacity and you agree to comply with these
					Terms of Service;
				</p>
				<p>
					(4) you are either age 13 or older, or if you are under 13 years old,
					your parents must have contacted us to authorize your use according to
					the provisions of the Children's Online Privacy Protection Act of
					1998;
				</p>
				<p>
					(5) you will not access the Site through automated or non-human means,
					whether through a bot, script, or otherwise, unless it is a
					maintenance bot for your wiki given prior approval by us;
				</p>
				<p>
					(6) you will not use the Site for any illegal or unauthorized purpose;
				</p>
				<p>
					(7) your use of the Site will not violate any applicable law or
					regulation.
				</p>

				<p>
					If you provide any information that is untrue, inaccurate, not
					current, or incomplete, we have the right to suspend or terminate your
					account and refuse any and all current or future use of the Site (or
					any portion thereof), without refund (if applicable).
				</p>

				<h2>Section 3. Prohibited Activities</h2>
				<p>
					You may not access or use the Site for any purpose other than that for
					which we make the Site available. The Site may not be used in
					connection with any commercial endeavors except those that are
					specifically endorsed or approved by us.
				</p>

				<p>As a user of the Site or a Wiki Owner, you agree not to:</p>

				<ol>
					<li>
						systematically retrieve data or other content from the Site to
						create or compile, directly or indirectly, a collection,
						compilation, database, or directory without written permission from
						us.
					</li>
					<li>
						make any unauthorized use of the Site, including collecting
						usernames and/or email addresses of users by electronic or other
						means for the purpose of sending unsolicited email, or creating user
						accounts by automated means or under false pretenses.
					</li>
					<li>
						use a buying agent or purchasing agent to make purchases on the
						Site.
					</li>
					<li>
						use the Site to advertise or offer to sell goods and services
						contrary to the wiki's purpose; this specifically does not apply to
						wiki owners using their wiki to advertise their products.
					</li>
					<li>
						circumvent, disable, or otherwise interfere with security-related
						features of the Site, including features that prevent or restrict
						the use or copying of any Content or enforce limitations on the use
						of the Site and/or the Content contained therein.
					</li>
					<li>engage in unauthorized framing of or linking to the Site.</li>
					<li>
						trick, defraud, or mislead us and other users, especially in any
						attempt to learn sensitive account information such as user
						passwords;
					</li>
					<li>
						make improper use of our support services or submit false reports of
						abuse or misconduct.
					</li>
					<li>
						engage in any automated use of the system, such as using scripts to
						send comments or messages, or using any data mining, robots, or
						similar data gathering and extraction tools; except for the use of
						the API in a manner approved by us.
					</li>
					<li>
						interfere with, disrupt, or create an undue burden on the Site or
						the networks or services connected to the Site.
					</li>
					<li>
						use of any automated monitoring systems to monitor the uptime or
						service reliability of the Site without our express written consent.
					</li>
					<li>
						attempt to impersonate another user or person or use the username of
						another user.
					</li>
					<li>sell or otherwise transfer your profile.</li>
					<li>
						use any information obtained from the Site in order to harass,
						abuse, or harm another person.
					</li>
					<li>
						use the Site as part of any effort to compete with us or otherwise
						use the Site and/or the Content for any revenue-generating endeavor
						or commercial enterprise.
					</li>
					<li>
						decipher, decompile, disassemble, or reverse engineer any of the
						software comprising or in any way making up a part of the Site.
					</li>
					<li>
						attempt to bypass any measures of the Site designed to prevent or
						restrict access to the Site, or any portion of the Site.
					</li>
					<li>
						harass, annoy, intimidate, use profanity towards, or threaten any of
						our employees or agents engaged in providing any portion of the Site
						to you.
					</li>
					<li>
						delete the copyright, licensing, or other proprietary rights notice
						from any Content.
					</li>
					<li>
						copy or adapt the Site’s software, including but not limited to PHP,
						HTML, JavaScript, or other code.
					</li>
					<li>
						upload or transmit (or attempt to upload or to transmit) viruses,
						Trojan horses, or other material, including excessive use of capital
						letters and spamming (continuous posting of repetitive text), that
						interferes with any party’s uninterrupted use and enjoyment of the
						Site or modifies, impairs, disrupts, alters, or interferes with the
						use, features, functions, operation, or maintenance of the Site.
					</li>
					<li>
						upload or transmit (or attempt to upload or to transmit) any
						material that acts as a passive or active information collection or
						transmission mechanism, including without limitation, clear graphics
						interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
						other similar devices (sometimes referred to as “spyware” or
						“passive collection mechanisms” or “pcms”).
					</li>
					<li>
						except as may be the result of standard search engine or Internet
						browser usage, use, launch, develop, or distribute any automated
						system, including without limitation, any spider, robot, cheat
						utility, scraper, or offline reader that accesses the Site, or using
						or launching any unauthorized script or other software.
					</li>
					<li>
						disparage, tarnish, or otherwise harm, in our opinion, us and/or the
						Site.
					</li>
					<li>
						use the Site in a manner inconsistent with any applicable laws or
						regulations.
					</li>
				</ol>

				<p>
					Violations of these stipulations may result in, but are not limited
					to: suspension or termination of your account and wiki, without
					possibility of refund; appropriate legal action against the account
					holder in order to protect us, the Site, and other Users; and other
					necessary actions. We reserve the exclusive right to determine whether
					a violation has occurred and the necessary actions we must take in
					response.
				</p>

				<h2>Section 4. User Generated Contributions</h2>
				<p>
					The Site may invite you to chat, contribute to, or participate in
					wikis, blogs, message boards, online forums, and other functionality,
					and may provide you with the opportunity to create, submit, post,
					display, transmit, perform, publish, distribute, or broadcast content
					and materials to us or on the Site, including but not limited to text,
					writings, video, audio, photographs, graphics, comments, suggestions,
					or personal information or other material (collectively,
					“Contributions”).
				</p>
				<p>
					Contributions may be viewable by other users of the Site and through
					third-party websites. As such, any Contributions you transmit may be
					treated as non-confidential and non-proprietary. When you create or
					make available any Contributions, you thereby represent and warrant
					that:
				</p>

				<ol>
					<li>
						the creation, distribution, transmission, public display, or
						performance, and the accessing, downloading, or copying of your
						Contributions do not and will not infringe the proprietary rights,
						including but not limited to the copyright, patent, trademark, trade
						secret, or moral rights of any third party.
					</li>
					<li>
						you are the creator and owner of or have the necessary licenses,
						rights, consents, releases, and permissions to use and to authorize
						us, the Site, and other users of the Site to use your Contributions
						in any manner contemplated by the Site and these Terms of Service.
					</li>
					<li>
						you have the written consent, release, and/or permission of each and
						every identifiable individual person in your Contributions to use
						the name or likeness of each and every such identifiable individual
						person to enable inclusion and use of your Contributions in any
						manner contemplated by the Site and these Terms of Service.
					</li>
					<li>your Contributions are not false, inaccurate, or misleading.</li>
					<li>
						your Contributions are not unsolicited or unauthorized advertising,
						promotional materials, pyramid schemes, chain letters, spam, mass
						mailings, or other forms of solicitation.
					</li>
					<li>
						your Contributions are not obscene, lewd, lascivious, filthy,
						violent, harassing, libelous, slanderous, or otherwise objectionable
						(as determined by us).
					</li>
					<li>
						your Contributions do not ridicule, mock, disparage, intimidate, or
						abuse anyone.
					</li>
					<li>
						your Contributions do not advocate the violent overthrow of any
						government or incite, encourage, or threaten physical harm against
						another.
					</li>
					<li>
						your Contributions do not violate any applicable law, regulation, or
						rule.
					</li>
					<li>
						your Contributions do not violate the privacy or publicity rights of
						any third party.
					</li>
					<li>
						your Contributions do not contain any material that solicits
						personal information from anyone in a nonconsensual way or exploits
						people in a sexual or violent manner.
					</li>
					<li>
						your Contributions do not violate any federal or state law
						concerning pornography, or otherwise intended to protect the health
						or well-being of people;
					</li>
					<li>
						your Contributions do not include any offensive comments that are
						connected to race, national origin, gender, sexual preference, or
						physical handicap.
					</li>
					<li>
						your Contributions do not otherwise violate, or a to material
						that violates, any provision of these Terms of Service, or any
						applicable law or regulation.
					</li>
				</ol>

				<p>
					Any use of the Site in violation of the foregoing violates these Terms
					of Service and may result in, among other things, termination or
					suspension of your rights to use the Site, without possibility of
					refund.
				</p>

				<h2>Section 5. Contribution License</h2>

				<p>
					You must acknowledge that publicly available portions of the Site are,
					by their nature, open to anyone to view, and thus, the following
					terms set out the necessary legal protections to accommodate the
					ramifications associated with said portions's content being publicly
					accessible. By posting your Contributions to any part of the Site that is
					publicly accessible or making publicly-accessible Contributions to the Site
					by linking your account from the Site to any of your social networking
					accounts, you automatically grant, and you represent and warrant that
					you have the right to grant, to us an unrestricted, unlimited, irrevocable,
					perpetual, non-exclusive, transferable, royalty-free, fully-paid,
					worldwide right, and license to host, use, copy, reproduce, disclose, sell,
					resell, publish, broadcast, retitle, archive, store, cache, publicly
					perform, publicly display, reformat, translate, transmit, excerpt (in
					whole or in part), and distribute such Contributions (including,
					without limitation, your image and voice) for any purpose, commercial,
					advertising, or otherwise, and to prepare derivative works of, or
					incorporate into other works, such Contributions, and grant and
					authorize sublicenses of the foregoing. The use and distribution may
					occur in any media formats and through any media channels. For portions of the
					Site which have viewing restrictions (also called privately available portions
					of the Site), the foregoing terms in this paragraph shall apply except
					when we use, copy, reproduce, disclose, sell, resell, publish, broadcast,
					retitle, archive, store, cache, publicly perform, publicly display, reformat,
					translate, transmit, excerpt (in whole or in part), or distribute such
					Contributions, the scope of these actions shall be exclusively limited to the
					individuals and entities who are restricted to viewing said Contributions in
					the first place, as well as for the strictly necessary conduct of our business
					and in response to necessary subpoenas issued by competent courts with
					jurisdiction over us presented to us by law enforcement or other competent
					authorities who have jurisdiction over us, and in such cases where a necessary
					distribution of Contributions take place, we may notify you unless enjoined
					by a gag order or any other order made by a competent court with jurisdiction
					to do so.
				</p>
				<p>
					This license will apply to any form, media, or technology now known or
					hereafter developed, and includes our use of your name, company name,
					and franchise name, as applicable, and any of the trademarks, service
					marks, trade names, logos, and personal and commercial images you
					provide. You waive all moral rights in your Contributions, and you
					warrant that moral rights have not otherwise been asserted in your
					Contributions.
				</p>
				<p>
					We do not assert any ownership over your Contributions. You retain
					full ownership of all of your Contributions and any intellectual
					property rights or other proprietary rights associated with your
					Contributions. We are not liable for any statements or representations
					in your Contributions provided by you in any area on the Site.
				</p>
				<p>
					You are solely responsible for your Contributions to the Site and you
					expressly agree to exonerate us from any and all responsibility and to
					refrain from any legal action against us regarding your Contributions.
				</p>
				<p>
					We have the right, in our sole and absolute discretion, (1) to edit,
					redact, or otherwise change any Contributions; (2) to re-categorize
					any Contributions to place them in more appropriate locations on the
					Site; and (3) to pre-screen or delete any Contributions at any time
					and for any reason, without notice. We have no obligation to monitor
					your Contributions.
				</p>
				<p>
					MyWikis shall offer content dumps of wikis through the wiki's
					Special:Export page or by request on the support system located at{" "}
					<a href="https://www.mywikis.com/contact">
						https://www.mywikis.com/contact
					</a>
					. However, we are under no obligation to offer a comprehensive
					database dump or the wiki's configuration files, per our privacy
					policy's data protection policies. Sensitive information lies within
					our databases. Since sharing a database dump would mean we could not
					guarantee data security, we cannot share it with any Users.
				</p>
				<p>
					For corporate users only: If you are a representative of a company,
					you agree that by requesting to begin a wiki subscription with us, we
					may use your company's brand and logo only for marketing purposes
					without explicit notice. This applies regardless of whether you
					initiate payments to us or not. You agree to allow us to use your
					company's brand and logo indefinitely. You also agree to hold MyWikis
					harmless as a result of our usage of your brand. If you do not agree
					with this clause, you must send MyWikis a written notice through our
					support system within 30 days of your first payment to us, located at{" "}
					<a href="https://www.mywikis.com/contact">
						https://www.mywikis.com/contact
					</a>
					, indicating you would like to opt-out from our marketing. After we
					confirm you have been opted out, you will no longer be bound by the
					terms of this specific clause, although all other parts of this Terms
					of Service apply.
				</p>

				<h2>Section 6. Submissions</h2>
				<p>
					You acknowledge and agree that any questions, comments, suggestions,
					ideas, feedback, or other information regarding the Site
					(“Submissions”) provided by you to us are non-confidential and shall
					become our sole property. We shall own exclusive rights, including all
					intellectual property rights, and shall be entitled to the
					unrestricted use and dissemination of these Submissions for any lawful
					purpose, commercial or otherwise, without acknowledgment or
					compensation to you. We may also choose to extend these rights, in a
					more limited or full capacity, to Wiki Owners for content on their
					respective wikis.
				</p>
				<p>
					You hereby waive all moral rights to any such Submissions, and you
					hereby warrant that any such Submissions are original with you or that
					you have the right to submit such Submissions. You agree there shall
					be no recourse against us for any alleged or actual infringement or
					misappropriation of any proprietary right in your Submissions.
				</p>

				<h2>Section 7. Third-Party Websites and Content</h2>
				<p>
					The Site may contain (or you may be sent via the Site) as to other
					websites (“Third-Party Websites”) as well as articles, photographs,
					text, graphics, pictures, designs, music, sound, video, information,
					applications, software, and other content or items belonging to or
					originating from third parties (“Third-Party Content”).
				</p>
				<p>
					Such Third-Party Websites and Third-Party Content are not
					investigated, monitored, or checked for accuracy, appropriateness, or
					completeness by us, and we are not responsible for any Third-Party
					Websites accessed through the Site or any Third-Party Content posted
					on, available through, or installed from the Site, including the
					content, accuracy, offensiveness, opinions, reliability, privacy
					practices, or other policies of or contained in the Third-Party
					Websites or the Third-Party Content.
				</p>
				<p>
					Inclusion of, linking to, or permitting the use or installation of any
					Third-Party Websites or any Third-Party Content does not imply
					approval or endorsement thereof by us. In fact, most Third-Party
					Content on our Site is added by other users of the Site. If you decide
					to leave the Site and access the Third-Party Websites or to use or
					install any Third-Party Content, you do so at your own risk, and you
					should be aware these Terms of Service no longer govern.
				</p>
				<p>
					You should review the applicable terms and policies, including privacy
					and data gathering practices, of any website to which you navigate
					from the Site or relating to any applications you use or install from
					the Site. Any purchases you make through Third-Party Websites will be
					through other websites and from other companies, and we take no
					responsibility whatsoever in relation to such purchases which are
					exclusively between you and the applicable third party.
				</p>
				<p>
					You agree and acknowledge that we do not endorse the products or
					services offered on Third-Party Websites and you shall hold us
					harmless from any harm caused by your purchase of such products or
					services. Additionally, you shall hold us harmless from any losses
					sustained by you or harm caused to you relating to or resulting in any
					way from any Third-Party Content or any contact with Third-Party
					Websites.
				</p>
				<p>
					Wiki Owners must be responsible for ensuring compliance with our
					policies on their wikis. If a wiki is found to not comply with our
					terms, we may hold the Wiki Owner responsible.
				</p>

				<h2>Section 8. Site Management</h2>
				<p>We reserve the right, but not the obligation, to:</p>

				<ol>
					<li>monitor the Site for violations of these Terms of Service;</li>
					<li>
						take appropriate legal action against anyone who, in our sole
						discretion, violates the law or these Terms of Service, including
						without limitation, reporting such user to law enforcement
						authorities;
					</li>
					<li>
						in our sole discretion and without limitation, refuse, restrict
						access to, limit the availability of, or disable (to the extent
						technologically feasible) any of your Contributions or any portion
						thereof;
					</li>
					<li>
						in our sole discretion and without limitation, notice, or liability,
						to remove from the Site or otherwise disable all files and content
						that are excessive in size or are in any way burdensome to our
						systems;
					</li>
					<li>
						otherwise manage the Site in a manner designed to protect our rights
						and property and to facilitate the proper functioning of the Site.
					</li>
				</ol>

				<p>
					In any of the following cases, we are not responsible, nor shall we
					provide, a refund or credit as a result of taking down, deleting, or
					otherwise destroying data per the aforementioned enumerated reasons.
				</p>

				<h2 id="dmca">
					Section 9. Copyright Infringements and Digital Millennium Copyright
					Act (DMCA) Notice and Policy
				</h2>

				<p>
					We respect the intellectual property rights of others. If you believe
					that any material available on or through the Site infringes upon any
					copyright you own or control, please immediately notify us by
					submitting a message to{" "}
					<a href="https://www.mywikis.com/contact">
						https://www.mywikis.com/contact
					</a>{" "}
					(a “Notification”).
				</p>
				<p>
					A copy of your Notification will be sent to the person who posted or
					stored the material addressed in the Notification, as well as the Wiki
					Owner (if they are not the same person). Please be advised that
					pursuant to federal law you may be held liable for damages if you make
					material misrepresentations in a Notification. Thus, if you are not
					sure that material located on or linked to by the Site infringes your
					copyright, you should consider first contacting an attorney.
				</p>
				<p>
					All Notifications should meet the requirements of DMCA 17 U.S.C. §
					512(c)(3) and include the following information:
				</p>
				<ol>
					<li>
						A physical or electronic signature of a person authorized to act on
						behalf of the owner of an exclusive right that is allegedly
						infringed;
					</li>
					<li>
						identification of the copyrighted work claimed to have been
						infringed, or, if multiple copyrighted works on the Site are covered
						by the Notification, a representative list of such works on the
						Site;
					</li>
					<li>
						identification of the material that is claimed to be infringing or
						to be the subject of infringing activity and that is to be removed
						or access to which is to be disabled, and information reasonably
						sufficient to permit us to locate the material;
					</li>
					<li>
						information reasonably sufficient to permit us to contact the
						complaining party, such as an address, telephone number, and, if
						available, an email address at which the complaining party may be
						contacted;
					</li>
					<li>
						a statement that the complaining party has a good faith belief that
						use of the material in the manner complained of is not authorized by
						the copyright owner, its agent, or the law;
					</li>
					<li>
						a statement that the information in the notification is accurate,
						and under penalty of perjury, that the complaining party is
						authorized to act on behalf of the owner of an exclusive right that
						is allegedly infringed upon.
					</li>
				</ol>
				<p>
					If you believe your own copyrighted material has been removed from the
					Site as a result of a mistake or misidentification, you may submit a
					written counter notification to us by submitting a message to{" "}
					<a href="https://www.mywikis.com/contact">
						https://www.mywikis.com/contact
					</a>{" "}
					(a “Counter Notification”). To be an effective Counter Notification
					under the DMCA, your Counter Notification must include substantially
					the following:
				</p>
				<ol>
					<li>
						identification of the material that has been removed or disabled and
						the location at which the material appeared before it was removed or
						disabled;
					</li>
					<li>
						a statement that you consent to the jurisdiction of the Federal
						District Court in which your address is located, or if your address
						is outside the United States, for any judicial district in which we
						are located (namely, the Eastern District of Texas, although this is
						subject to change);
					</li>
					<li>
						a statement that you will accept service of process from the party
						that filed the Notification or the party’s agent;
					</li>
					<li>your name, address, and telephone number;</li>
					<li>
						a statement under penalty of perjury that you have a good faith
						belief that the material in question was removed or disabled as a
						result of a mistake or misidentification of the material to be
						removed or disabled;
					</li>
					<li>your physical or electronic signature.</li>
				</ol>
				<p>
					If you send us a valid, written Counter Notification meeting the
					requirements described above, we will restore your removed or disabled
					material, unless we first receive notice from the party filing the
					Notification informing us that such party has filed a court action to
					restrain you from engaging in infringing activity related to the
					material in question.
				</p>
				<p>
					Please note that if you materially misrepresent that the disabled or
					removed content was removed by mistake or misidentification, you may
					be liable for damages, including costs and attorney’s fees. Filing a
					false Counter Notification constitutes perjury.
				</p>

				<h2>Section 10. Terms and Termination</h2>
				<p>
					These Terms of Service shall remain in full force and effect while you
					use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF
					SERVICE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
					NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING
					BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
					REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
					WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF SERVICE OR OF ANY
					APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
					PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
					INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
					DISCRETION.
				</p>
				<p>
					If we terminate or suspend your account for any reason, you are
					prohibited from registering and creating a new account under your
					name, a fake or borrowed name, or the name of any third party, even if
					you may be acting on behalf of the third party.
				</p>
				<p>
					In addition to terminating or suspending your account, we reserve the
					right to take appropriate legal action, including without limitation
					pursuing civil, criminal, and injunctive redress.
				</p>

				<h2>Section 11. Modifications and Interruptions</h2>
				<p>
					We reserve the right to change, modify, or remove the contents of the
					Site at any time or for any reason at our sole discretion without
					notice. However, we have no obligation to update any information on
					our Site. We also reserve the right to modify or discontinue all or
					part of the Site without notice at any time.
				</p>
				<p>
					We will not be liable to you or any third party for any modification,
					price change, suspension, or discontinuance of the Site.
				</p>
				<p>
					We cannot guarantee the Site will be available at all times. We may
					experience hardware, software, or other problems or need to perform
					maintenance related to the Site, resulting in interruptions, delays,
					or errors. You agree that our service level agreements (SLAs) are
					nonbinding and are only goals we strive to achieve. We also reserve
					the right to change the SLA at any time, temporarily or permanently,
					without prior warning, for any reason.
				</p>
				<p>
					We reserve the right to change, revise, update, suspend, discontinue,
					or otherwise modify the Site at any time or for any reason without
					notice to you. You agree that we have no liability whatsoever for any
					loss, damage, or inconvenience caused by your inability to access or
					use the Site during any downtime or discontinuance of the Site.
				</p>
				<p>
					Nothing in these Terms of Service will be construed to obligate us to
					maintain and support the Site or to supply any corrections, updates,
					or releases in connection therewith.
				</p>

				<h2>Section 12. Governing Law</h2>
				<p>
					These Terms of Service and your use of the Site are governed by and
					construed in accordance with the laws of the United States of America
					and the State of Texas applicable to agreements made and to be
					entirely performed within the State of Texas, without regard to its
					conflict of law principles.
				</p>
				<p>
					We will bring any adversarial proceeding under these Terms in the
					courts of King County, Washington, United States of America under
					the laws of the State of Texas for any potential litigation we might
					initiate against you.
				</p>
				<p>
					You must submit to the exclusive jurisdiction of such courts according
					to the above terms and waive any venue objection.
				</p>

				<h2>Section 13. Corrections</h2>
				<p>
					There may be information on the Site that contains typographical
					errors, inaccuracies, or omissions that may relate to the Site,
					including descriptions, pricing, availability, and various other
					information. We reserve the right to correct any errors, inaccuracies,
					or omissions and to change or update the information on the Site at
					any time, without prior notice.
				</p>

				<h2>Section 14. Disclaimer</h2>
				<p>
					THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
					THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK.
					TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
					EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
					INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
					MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
					NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
					ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
					WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
					RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
					CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
					NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE,
					(3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
					AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
					THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
					THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY
					BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
					ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS
					OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
					POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO
					NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
					PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
					SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
					FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
					PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
					BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
				</p>
				<p>
					AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN
					ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
					CAUTION WHERE APPROPRIATE.
				</p>

				<h2>Section 15. Limitations of Liability</h2>
				<p>
					IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
					TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
					EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
					PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
					USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
					SUCH DAMAGES.{" "}
					<b>
						BY USING OUR SERVICES, YOU HEREBY WAIVE, RELEASE, AND DISCHARGE
						MYWIKIS AND ITS AGENTS AND EMPLOYEES OF ANY AND ALL LIABILITY OR
						DAMAGES OF ANY KIND RELATED TO, ARISING FROM, OR IN ANY WAY
						CONNECTED WITH, YOUR USAGE OF OUR SERVICES, INCLUDING THOSE
						ALLEGEDLY ATTRIBUTED TO THE NEGLIGENT ACTS OR OMISSIONS OF THE
						AFOREMENTIONED PARTIES.
					</b>
				</p>

				<h2>Section 16. Indemnification</h2>
				<p>
					You agree to defend, indemnify, and hold us harmless, including our
					subsidiaries, affiliates, and all of our respective officers, agents,
					partners, and employees, from and against any loss, damage, liability,
					claim, or demand, including reasonable attorneys’ fees and expenses,
					made by any third party due to or arising out of: (1) your
					Contributions; (2) use of the Site; (3) breach of these Terms of
					Service; (4) any breach of your representations and warranties set
					forth in these Terms of Service; (5) your violation of the rights of a
					third party, including but not limited to intellectual property
					rights; or (6) any overt harmful act toward any other user of the Site
					with whom you connected via the Site.
				</p>
				<p>
					Notwithstanding the foregoing, we reserve the right, at your expense,
					to assume the exclusive defense and control of any matter for which
					you are required to indemnify us, and you agree to cooperate, at your
					expense, with our defense of such claims. We will use reasonable
					efforts to notify you of any such claim, action, or proceeding which
					is subject to this indemnification upon becoming aware of it.
				</p>
				<p>
					You agree to waive your right to sue us in a court, and that any disputes
					involving MyWikis must be resolved by arbitration chosen by us.
					Class-action suits must be individualized.
				</p>

				<h2>Section 17. User Data</h2>
				<p>
					We will maintain certain data that you transmit to the Site for the
					purpose of managing the Site, as well as data relating to your use of
					the Site. Although we perform regular routine backups of data, you are
					solely responsible for all data that you transmit or that relates to
					any activity you have undertaken using the Site.
				</p>
				<p>
					You agree that we shall have no liability to you for any loss or
					corruption of any such data, and you hereby waive any right of action
					against us arising from any such loss or corruption of such data.
				</p>

				<h2>
					Section 18. Electronic Communications, Transactions, and Signatures
				</h2>
				<p>
					Visiting the Site, sending us emails, and completing online forms
					constitute electronic communications. You consent to receive
					electronic communications, and you agree that all agreements, notices,
					disclosures, and other communications we provide to you
					electronically, via email and on the Site, satisfy any legal
					requirement that such communication be in writing.
				</p>
				<p>
					YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
					ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
					POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR
					VIA THE SITE.
				</p>
				<p>
					You hereby waive any rights or requirements under any statutes,
					regulations, rules, ordinances, or other laws in any jurisdiction
					which require an original signature or delivery or retention of
					non-electronic records, or to payments or the granting of credits by
					any means other than electronic means.
				</p>

				<h2>Section 19. Billing Policies</h2>
				<p>
					<b>Payment methods</b>: MyWikis takes credit cards, bank transfer (EU
					via SEPA, UK via BACS and CHAPS, and U.S. via ACH), and PayPal.
					MyWikis bills from the United States (for non-EU customers, under
					MyWikis LLC) and the United Kingdom (for EU, EEA, Swiss, and UK
					customers, under MyWikis Europe). If you are outside of the United
					States, the European Union, or the United Kingdom, you understand that
					your card issuer, bank, or payments network may charge a foreign
					transaction fee for any payments MyWikis charges against your card.
					Card and bank account details are not fully accessible to us and are
					securely stored encrypted on Stripe's servers. Contact our billing
					department if you need a credit card removed from your account. You
					are responsible for keeping your credit card up to date. Our system
					will send automated reminders when your credit card on file is about
					to expire, but you will need to provide the new card information
					before your current card on file expires.
				</p>

				<p>
					<b>Recurring payments</b>: By providing your credit card information
					and saving it in our billing system, you authorize us to automatically
					charge your card at regular intervals (recurring payments). If you no
					longer consent to your card being charged, you agree to inform us
					immediately via{" "}
					<a href="https://www.mywikis.com/contact">
						https://www.mywikis.com/contact
					</a>{" "}
					by providing us the last 4 digits of the card you no longer wish for
					us to charge against, and you agree that any payments charged prior to
					the request being lodged will not be refunded. Any charges made after
					the request is lodged will be refunded upon request unless it has been
					used to pay for an outstanding invoice or will be used to pay for a
					future invoice.
				</p>

				<p>
					<b>Invoices</b>: Invoices are our unit of billing. Every payment you
					make will have an invoice backed behind it. You may access your
					invoices by logging in and visiting them. You can even print them.
				</p>

				<p>
					<b>Payment rates</b>: MyWikis reserves the right to change the price
					of a subscription at any time, without any prior notice needed,
					although we usually will not raise the subscription prices of any
					existing customers; in other words, any price increases will only
					apply to future customers unless we state otherwise. MyWikis also
					reserves the right to price the same product differently in different
					currencies, in order to account for currency exchange rate differences
					and fluctuations. By using the Site, you agree to pay using the
					currency most appropriate to you. For instance, if your address is in
					the United Kingdom, you agree to use the Pound Sterling (GBP) as
					payment rather than the Euro (EUR) or the United States Dollar (USD).
					In case we do not take your local currency, you agree to use the
					United States Dollar (USD) rate.
				</p>

				<p>
					<b>Payment finality</b>: By making a payment to MyWikis, you agree
					that (1) the amount you have paid is correct; (2) you are sure you
					want to pay this amount, or you are sure that you wish to enter a
					recurring payment contract to pay this amount each billing cycle; (3)
					you understand the payment is final and that there are no refunds; and
					(4) you will not attempt to defraud, swindle, coerce, or otherwise
					unfairly take back the money paid for services we have rendered.
					MyWikis shall return any money deemed mistakenly charged; for this,
					you agree to contact MyWikis first before pursuing action from your
					credit card company, PayPal, or your bank. You agree to not attempt a
					credit card chargeback or make a PayPal dispute after we provide you
					with service, or you will be permanently banned from the Site. MyWikis
					reserves the right to pursue legal action against you to reclaim money
					via chargebacks made for services we have already rendered. Legal action
					and litigation shall be pursued according to Section 12: Governing Law
					of these Terms, in small claims court in the appropriate jurisdiction
					specified in Section 12 if we seek damages under $10,000 and in an
					appropriate state or federal venue if we seek damages over $10,000.
					MyWikis is under no obligation to return overpaid money, and will
					treat it as early payment credited to your account to apply for
					future invoices.
				</p>

				<p>
					<b>Cancellation</b>: In case you would like to cancel your wiki
					subscription, you should do so through the Client Panel by logging in
					and opening a support ticket. By canceling your subscription, you
					agree that the wiki may be immediately deleted, before the end of the
					current billing cycle. You also agree that the remaining time left on
					the subscription will not be refunded in cash, but upon request, we
					will provide a billing credit towards future use.
				</p>

				<p>
					<b>Charging schedule</b>: MyWikis will generate an invoice for your
					payment approximately two weeks before it is due, although we are
					under no obligation to provide an invoice until the date of billing.
					We reserve the right to make a charge against the payment method(s)
					you have on file before the invoice due date, as long as the invoice
					has been generated. Should you wish to cancel, it is up to you to
					request cancelation before the next invoice is charged.
				</p>

				<p>
					<b>Payment delinquency</b>: In case we are unable to bill your payment
					method(s) for an invoice, we reserve the right to suspend your
					service(s) as soon as the due date of the unpaid invoice, and keep it
					suspended until the invoice is paid.
				</p>

				<p>
					<b>Upgrade prorata</b>: During upgrades from one subscription level to
					another, an invoice may be generated to cover the difference between
					now and the next billing date. This difference will be calculated
					according to prorata rates determined by our software. The price will
					be between $0 and the full price of the subscription for a certain
					time period. By upgrading, you agree to pay the calculated prorated
					amount our system generates.
				</p>

				<p>
					<b>Automatic upgrades</b>: If your wiki exceeds any quota of your plan
					(for instance, storage), your wiki can be automatically upgraded to a
					higher plan. No prior notice is necessary, and the upgrade will not
					require your permission. Grandfathered price for the upgraded plan is
					subject to MyWikis discretion.
				</p>

				<p>
					<b>Refunds</b>: We do not provide refunds unless a billing error has
					been made (e.g. overcharges, unintended charges, etc.), and are under
					no obligation at any time to offer refunds. Since we offer the option
					to try out our demo (completely free of charge and no credit card
					required) before paying for a wiki subscription, we do not offer any
					sort of money back guarantee. In some cases, we may provide a billing
					credit instead of a refund, especially if you intend to continue using
					our services in future billing cycles. Again, WE DO NOT PROVIDE
					REFUNDS. If you are unsure whether a MyWikis wiki is the right choice
					for you, please try our demo first before registering and/or ask our
					sales department before making a purchase!
				</p>

				<p>
					<b>Early payment/overpayment</b>: Occasionally, clients will pay
					earlier before our system is able to generate an invoice. This is
					normal, given the potential disconnect between PayPal subscriptions'
					renewal dates and the invoice generation date. In such cases, our
					system should be able to automatically issue credits to your account,
					which will be deducted from the amount to be paid on future generated
					invoices. Should there be a consistent overpayment which grows at a
					faster rate than your account's spending, the charge rate will be
					adjusted once it is brought to our attention and the credits on your
					account applied first before any further transactions are charged to
					your payment method(s).
				</p>

				<p>
					<b>Billing errors</b>: The price we advertise is the price at which we
					bill. If our billing system makes an error, it is your responsibility
					to let us know so we may fix it, whether there was an undercharge or
					an overcharge. In case of an overcharge, please see the above clauses
					on "Payment finality" and "Refunds" for more information. If there is
					an undercharge, however, we reserve the right to change existing
					invoices and rectify them at any time, even after an invoice was paid,
					and we reserve the right to collect payment for the missing amount. We
					will notify clients should they have been paying less money than was
					expected. We conduct regular audits of our bills to ensure their
					accuracy and expect any errors to be rectified. Intentionally hiding
					an undercharge or refusing to pay back the difference is considered
					theft and may result in termination of service and legal action.
				</p>

				<p>
					<b>Wiki suspension due to payment deliquency</b>: If the wiki
					subscription still cannot be successfully renewed after this grace
					period, the wiki will be suspended. In other words, it will no longer
					be available to use and may be able to be restored if payment is made
					again. Should a wiki be suspended due to delinquent payments, payment
					may still be due for the time the wiki was suspended.
				</p>

				<p>
					<b>Issues with payment methods</b>: We reserve the right to try all payment
					methods on file to collect outstanding invoices until the payment is
					successfully collected. If the incorrect payment method is used (e.g. a
					resident of the United Kingdom used the non-European credit card payment
					method), we reserve the right to charge an additional fee, to be determined by
					us on a case-by-case basis, up to 30% of the total invoiced amount. If this fee
					is assessed, it will appear as a line item on the affected invoice. This fee
					will only be assessed as a last resort when attempts to reach you have failed.
					If you do not wish to use a payment method anymore, please contact us to have
					it removed. Until you have requested a payment method be removed and we have
					confirmed it to you in writing, we shall be able to continue charging
					this payment method.
				</p>

				<h2>Section 20. Miscellaneous</h2>
				<p>
					These Terms of Service and any policies or operating rules posted by
					us on the Site constitute the entire agreement and understanding
					between you and us. Our failure to exercise or enforce any right or
					provision of these Terms of Service shall not operate as a waiver of
					such right or provision.
				</p>
				<p>
					These Terms of Service operate to the fullest extent permissible by
					law. We may assign any or all of our rights and obligations to others
					at any time. We shall not be responsible or liable for any loss,
					damage, delay, or failure to act caused by any cause beyond our
					reasonable control.
				</p>
				<p>
					If any provision or part of a provision of these Terms of Service is
					determined to be unlawful, void, or unenforceable, that provision or
					part of the provision is deemed severable from these Terms of Service
					and does not affect the validity and enforceability of any remaining
					provisions.
				</p>
				<p>
					There is no joint venture, partnership, employment or agency
					relationship created between you and us as a result of these Terms of
					Service or use of the Site. You agree that these Terms of Service will
					not be construed against us by virtue of having drafted them.
				</p>
				<p>
					You hereby waive any and all defenses you may have based on the
					electronic form of these Terms of Service and the lack of signing by
					the parties hereto to execute these Terms of Service.
				</p>

				<h2>Section 21. Contact Us</h2>
				<p>
					In order to resolve a complaint regarding the Site or to receive
					further information regarding use of the Site, please contact us at{" "}
					<a href="https://www.mywikis.com/contact">
						https://www.mywikis.com/contact
					</a>
					.
				</p>

				<h1>Privacy policy</h1>
				<p>Last updated: March 9, 2021</p>
				<p>
					We care about data privacy and security. By using the Site, you agree
					to be bound by our Privacy Policy, which is incorporated into these
					Terms of Service.
				</p>

				<h2>Section P1. Information collected</h2>
				<p>
					At MyWikis, we only collect the data that is necessary in the
					operation of our services. Namely, we collect, from all users:
				</p>
				<ul>
					<li>
						Your IP address, to ensure you are not a spambot or doppelganger. We
						do not use your IP address to track you for analytics purposes.
					</li>
					<li>
						The web pages you requested, to ensure you are not a spambot or
						doppelganger.
					</li>
					<li>Your user agent.</li>
					<li>The date and time at which you accessed the Site.</li>
				</ul>
				<p>
					In addition, we collect the following information from users who sign
					up for our wiki hosting services, for necessary purposes only:
				</p>
				<ul>
					<li>Your name</li>
					<li>
						Your address and country, for tax calculation and legal compliance
						purposes.
					</li>
					<li>Your email address, to verify your identity and to reach you.</li>
					<li>
						Your phone number, if you choose to provide it, to verify your
						identity and to reach you.
					</li>
					<li>
						The password you have chosen, which will be stored obfuscated in our
						database. We will not be able to view your plaintext password.
					</li>
					<li>
						Your payment information, which is necessary to purchase MyWikis
						products and services. This can include credit cards, PayPal
						information, and/or bank account information. Credit card
						information is securely stored on Stripe's servers, which are PCI
						compliant. We cannot view your full credit card information.
					</li>
				</ul>
				<p>In addition, we collect the following from users on wikis:</p>
				<ul>
					<li>
						Your IP address, if you make an action against the database (e.g.
						editing, deleting, changing user permissions, etc.)
					</li>
					<li>
						Your username, if you sign up, to identify who you are and ensure
						you are authorized to view/edit content or perform actions on the
						wiki.
					</li>
					<li>
						The password you have chosen, which, once again, will be stored
						obfuscated in our database. We will not be able to view your
						plaintext password.
					</li>
					<li>
						Your email address, if you choose to provide it, to verify your
						account and to contact you.
					</li>
					<li>
						Your GPS location, in certain cases, in order to change to the
						appropriate language.
					</li>
				</ul>

				<p>
					We only use cookies for the essential functioning of our website. This
					may include, but is not limited to, your user login session. As of
					March 2021, we do not use cookies for analytics tracking purposes.
				</p>

				<p>
					We also store this information in our own databases in order to
					provide our services.
				</p>

				<p>
					These data may be stored indefinitely by us in order to (1) operate
					our essential services, (2) assist in our anti-spam compliance, or (3)
					comply with legal requirements. Most data may be deleted upon request
					if you decide to leave our services forever. The exception would be
					your IP address and other browser-identifying information stored in
					logs, which cannot be deleted on a per-IP address basis, and which we
					need to keep for anti-spam and legal compliance reasons.
				</p>

				<h2>Section P2. How your information is used</h2>
				<p>
					At MyWikis, we take safeguarding your data very seriously. Your
					information is only used for the essential functioning of our website,
					sending you important updates, in-house marketing, policy compliance,
					and legal compliance reasons.
				</p>

				<p>
					We will not share your information with other entities, except when it
					is necessary for us to provide our services, and for policy compliance
					and legal compliance reasons. Even if we need to share information, we
					will only share the bare minimum possible.
				</p>

				<p>
					Policy compliance: To ensure you are in compliance with our Terms of
					Service, we reserve the right to use your data internally to ensure
					you are properly using our website and not violating our Terms. Use of
					data in this manner will not be shared with anyone else.
				</p>

				<p>
					Legal compliance: Please be advised our Site and wikis are hosted in
					the United States. We are subject to United States and Texas laws. If
					you access the Site from the European Union, Asia, or any other region
					of the world with laws or other requirements governing personal data
					collection, use, or disclosure that differ from applicable laws in the
					United States, then through your continued use of the Site, you are
					transferring your data to the United States, and you expressly consent
					to have your data transferred to and processed in the United States.
				</p>
				<p>
					Further, we do not knowingly accept, request, or solicit information
					from children or knowingly market to children. Therefore, in
					accordance with the U.S. Children’s Online Privacy Protection Act, if
					we receive actual knowledge that anyone under the age of 13 has
					provided personal information to us without the requisite and
					verifiable parental consent, we will delete that information from the
					Site as quickly as is reasonably practical.
				</p>
				<p>
					We will share information with law enforcement only as required by the
					law and only to comply with a properly-served warrant or subpoena.
				</p>
				<p>
					Our servers are currently all located within the United States.
					However, in the event we begin using servers outside of the United
					States, we reserve the right to transfer your data outside of the
					United States.
				</p>

				<h2>Section P3. How your data is protected</h2>
				<p>
					Personal information is secure in our databases, which can only be
					accessed by us and the person who owns that data, except for
					usernames, which can be seen by anyone using the site.
				</p>
				<p>
					If you choose to disclose your personal information publicly on a wiki
					page, that is not subject to the protections contained herein.
				</p>

				{/* <p>At any time, Staff may view all and any personal information of Users of the Site for the purpose of operating the Service. Staff may not view your personal information with intent to redistribute it or use it for purposes outside the scope of the Company.</p>
    <p>MyWikis reserves the right to distribute your information to authorities with proper jurisdiction.</p>
    <p>MyWikis reserves the right to use this information at any time internally without any notice to whomever the information belongs. The Service will not sell this information to third-parties directly; see Section 8 for our disclaimer regarding the sale of information by Google and PayPal and other companies, over which we have no control.</p>
    <p>MyWikis shall not share this information with anyone except our business associates (i.e. businesses with which we conduct business), MyWikis employees, MyWikis contractors, or any associates of MyWikis. Associates of MyWikis include those who have signed a non-disclosure agreement with the Service.</p>
    <p>All forms submitted to the site can be stored in our databases. By submitting forms, you agree that these forms can be stored in our databases.</p>
    <p>MyWikis reserves the right to store cookies on your computer. By using our website, you agree that cookies may be stored on your computer at any time without warning.</p> */}

				{/* <h2><a id="thirdparty"></a>Our relationship with third parties and our relationship with Big Data</h2>
    <p>We use certain Google services, like AdSense and Analytics, on our site, so we may serve you better by earning revenue or collecting relevant data, respectively. We can't control what Google does with your visit data, but the Service itself does not directly sell the User's information to third parties. Other services that the service uses, like Google or PayPal, may unfortunately decide to do so. However, you agree that MyWikis does not have control over this data collection, nor can MyWikis guarantee privacy from the selling of information as a result of these other services's (Google, PayPal, etc.) data collection methods. Payments are handled by PayPal (our "payments provider"), and what they do with your data is regulated by themselves, as well as any jurisdiction PayPal falls under.</p>
    <p>We will never establish a direct a to wholesale buyers or sellers of Big Data. However, our above partners, to be clear, cannot be held accountable to this clause. We will always try our best to ensure our users' data is kept private, as we wish as well, but there are certain limits to how private we may keep our users.</p> */}

				<h2>Section P4. Data privacy law compliance (GDPR, LGPD)</h2>
				<p>
					MyWikis respects your right to privacy. This section of the privacy
					policy clarifies our customer-first data collection policies as
					required by the GDPR and the LGPD. The following section applies to
					European Union and Brazilian data subjects ONLY.
				</p>
				<h3>Your data</h3>
				<p>
					What personal data we collect:
					<br />
					On our website and support system: your name, your admin username,
					your email address, your phone number, and your password for the
					support website.
					<br />
					On our wikis: your username, your wiki account password, your real
					name (if you choose to provide it), your gender (if you choose to
					provide it), your email address (if you choose to provide it), your
					timezone (if you choose to provide it), your IP address, and the
					content and images (for private wikis).
				</p>
				<p>
					Source of data: the MyWikis main website, the MyWikis support website,
					or any of our wikis that store this information.
				</p>
				<p>
					Who we share it with: Usernames are public unless your wiki is
					private. Names given on our website and support system are not shared,
					but may appear if you provide it on our wikis. For all other data, we
					do not share your data unless required to be disclosed by law
					enforcement or governmental institutions with jurisdiction over us.
				</p>
				<p>
					What we do with the data and why we need to use your data:
					<br />
					Your username is used to identify you publicly on wikis. Your password
					is encrypted so we do not know its original value, but we use it to
					keep your account safe.
					<br />
					When creating a wiki, your real name is used to address you on our
					support website. Your email address and phone number are used to
					contact you about service updates, wiki creation info, or support
					ticket information.
					<br />
					If you provide your full name on a wiki, it is used to identify who
					you are among others. Your gender, if provided, is used to address you
					with the proper pronouns. Your timezone, if provided, is used to give
					you your local timezone. Your IP address is only collected on our
					wikis for spam and doppelganger prevention purposes.
					<br />
					Content and images are used for wiki pages and kept securely in
					databases/filesystems and only accessible by authentication.
				</p>
				<p>
					How long we keep the data: All data are kept indefinitely unless you
					request for us to delete it. This is only applicable to EU and
					Brazilian data subjects.
				</p>
				<p>
					Where we store the data and how it is handled: in each application's
					database or the filesystem, accessible only through proper
					authentication. It only flows from the database to the website, or to
					a staff member's computer during inspection. After inspection, the
					data are destroyed.
				</p>
				<h3>Accountability and management</h3>
				<p>
					Our data protection officer (DPO) is currently our CTO. Staff are
					trained to keep your data secure. Sub-processors of data will be
					listed under a future section titled "our relationship with third
					parties". Data breaches will be reported within 72 hours via our
					Twitter account @MyWikis and Medium (https://www.medium.com/mywikis),
					if not other sources too.
				</p>
				<h3>Data rights</h3>
				<p>
					You can see all data we have on you (except for the IP addresses the
					wikis have collected) through your preferences/profile pages on the
					respective services. You can request for a history of the IP addresses
					we have tracked you using on our wikis by placing a request on our
					support system. While we do not use this information actively except
					for spam prevention or doppelganger account investigations, you may
					request for us to delete such information.
				</p>
				<p>
					You can have us transfer this info to yourself or a third party. You
					can ask for clarification on how we use your personal information
					(although an explanation already exists in the "Your data" section of
					this privacy policy). You can rectify incorrect data we have on you.
					You can ask us to stop using your personal information to market to
					you (unsubscribing from our email list that we seldom use). You can
					ask us to keep your data but stop processing it. And lastly, you can
					object to our practices.
				</p>
				<p>
					You may use this web-based form to exercise your GDPR rights:{" "}
					<a href="privacylaws">data privacy wizard</a>.
				</p>

				<h1>Acceptable Use Policy (MyWikis.com)</h1>
				<p>Last updated: March 11, 2023</p>
				<p>
					MyWikis ("We", "Our") expects wikis to use our services in an
					acceptable manner. This Acceptable Use Policy (hereon also
					referred to as the "AUP") governs how your wiki may and may
					not be used.
				</p>
				<p>
					This Acceptable Use Policy only applies to websites hosted under
					the mywikis.com, mywikis.net, and mywikis.wiki domains, including
					wikis using custom domains that are ultimately linked to being
					hosted on one of the aforementioned domain names. Wikis hosted
					under these domains are governed by the laws of the United States
					of America. This AUP does not apply to websites hosted under the
					mywikis.eu domain, nor custom domains linked to the mywikis.eu
					domain.
				</p>
				<h2>Preface: A note of context</h2>
				<p>
					MyWikis strongly believes in the First Amendment rights of all
					users on MyWikis. We aim to avoid censorship unless absolutely
					necessary. MyWikis does not actively patrol wikis to ensure
					the content is considered acceptable under our standards.
					MyWikis also recognizes that freedom of speech is an essential
					right. One often contentious point is regarding content about
					political beliefs. We accept content of all political views as
					long as it obeys the laws of the United States of America.
				</p>
				<p>
					We are very careful to avoid censorship of legitimate discourse
					protected by the First Amendment, even if it is controversial.
					As long as the content does not violate the law, we refrain
					from censoring the content.
				</p>
				{/*<p>
					As of November 2022, in the entire history of our services being
					provided, we have never taken down a single wiki, nor censored
					a single page of content. We aim to continue this trend of
					restraint and caution in order to protect the freedom of speech
					of all wikis.
					</p>*/}
				<p>
					If you're considering joining MyWikis, please be considerate
					of our other clients and their wikis. Please don't be the one
					to ruin it for everyone else. We really, really, really don't
					want to remove anyone's content, so please don't make us start
					with yours.
				</p>
				<p>
					If you are in doubt whether your wiki's contents will be
					acceptable, please contact our sales team at mywikis.com/contact
					before signing up for our platform.
				</p>
				<h2>Acceptable and unacceptable content</h2>
				<p>
					Content hosted via MyWikis.com (also including all websites under
					the mywikis.wiki domain) is subject to the Terms of Service and
					Acceptable Use Policy. In addition, content must not be fall under
					one of the following categories of speech, which are NOT protected
					under the First Amendment to the Constitution of the United States
					of America:
				</p>
				<ul>
					<li>Obscenity</li>
					<li>Fighting words</li>
					<li>Defamation (including libel and slander)</li>
					<li>Child pornography</li>
					<li>Perjury</li>
					<li>Blackmail</li>
					<li>Incitement to imminent lawless action</li>
					<li>True threats</li>
					<li>Solicitations to commit crimes</li>
				</ul>
				<p>
					We consider all ad hominem attacks to be defamation.
				</p>
				<p>
					Pornography and other materials that are inappropriate for
					anyone under 18 years of age are required to be hosted on a
					virtual private server specially configured to not be on
					the same domain as any of our other wikis. This is to ensure
					compliance with appropriate industry standards for parental
					controls and child safety content filtering. Pornography and
					other adult-only content are therefore prohibited from our
					cloud Wiki as a Service platform.
				</p>
				<p>
					In general, MyWikis.com respects the First Amendment rights of
					all users, but in addition to the prohibitions listed above,
					we also reserve the right in extreme cases to prohibit
					content on a case-by-case basis if we deem it to cause an
					untenable burden or poses an untenable threat to the reputation
					of MyWikis LLC and/or ability of MyWikis LLC to do business.
				</p>
				<p>
					Content must also not violate any copyrights or be plagiarized.
				</p>
				<p>
					Content containing computer viruses or content designed to
					scam or deceive people is strictly prohibited. Such content
					will also summarily be reported to law enforcement.
				</p>
				<h2>Reasonable resource usage</h2>
				<p>
					Clients who host their wiki on our cloud Wiki as a Service
					platform, using plans such as Basics, Pro, Quantum, or Ultra
					must also ensure their resource usage remains reasonable.
				</p>
				<p>
					Wikis using a large amount of file storage but remains within
					their file storage quota will not be considered in violation
					of the AUP.
				</p>
				<p>
					MyWikis's cloud Wiki as a Service platform is not designed to act
					as a media streaming or file sharing server. Using the platform
					in this manner significantly degrades the experience of other
					wikis and users. Wikis intended to be used for this purpose will
					be required to move to a virtual private server plan offered by
					MyWikis.
				</p>
				<p>
					Wikis causing a disproportionate load on our servers, defined
					as above the 99th percentile among all of our wikis, or
					with resource loads causing disruptions to other wikis on
					MyWikis's cloud Wiki as a Service platform will be required to
					either (1) reduce their resource usage, or (2) move to a virtual
					private server plan offered by MyWikis.
				</p>
				<p>
					Wikis with traffic loads above the 99th percentile among all
					of our wikis will be required to move to a virtual private
					server plan offered by MyWikis.
				</p>
				<h2>In event of noncompliance</h2>
				<p>
					If we deem a wiki is not complying with this AUP, the wiki owner
					will be given written warnings sent via email from an email
					address under the @mywikis.com domain. In time sensitive cases,
					MyWikis reserves the right to take immediate action without
					warning or to take action before any notice is issued to the
					wiki owner. If further noncompliance occurs in non-time sensitive
					situations, MyWikis reserves the right to take actions to
					bring that wiki in line with the AUP after the warning is issued.
				</p>
				<p>
					Actions we may take to enforce our AUP include, but are not
					limited to:
				</p>
				<ul>
					<li>
						Partial removal of content;
					</li>
					<li>
						Removing and banning users from the wiki and our platform;
					</li>
					<li>
						Disabling features or functionality of the wiki that are
						causing noncompliance with the AUP;
					</li>
					<li>
						Complete removal of wiki from public view;
					</li>
					<li>
						Complete takedown of wiki from all access, including
						the ability of the wiki owner to access their own wiki; and
					</li>
					<li>
						Throttling of resources used by the wiki
					</li>
				</ul>
				<p>
					In no case will any wiki affected by compliance actions
					be provided by monetary compensation (such as a refund)
					unless required by law or enforced by a court with appropriate
					jurisdiction, or unless we willingly provide a refund at our discretion.
				</p>
				<p>
					Unless prohibited by law or the authorities of the United States
					federal government or the State of Texas government, MyWikis will
					provide contents for export in the event the wiki owner wishes
					to move their wiki somewhere else to be hosted.
				</p>
			</div>
		</div>
	)
}

export default Terms
