/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./Maintenance.css"
import default503Icon from "../../images/default-503.svg"

const Maintenance = () => {
	return (
		<div>
			<Helmet>
				<title>
					Wiki Under Maintenance | MyWikis | Personalized wiki hosting
				</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">
				Wiki Under Maintenance
			</div>
			<div className="default-503">
				<img className="default-503-icon" src={default503Icon} alt="503-icon" />
				<div className="heading-primary-dark">
					Oops! This wiki is currently unavailable due to maintenance being
					performed by MyWikis.
				</div>{" "}
				<br />
				<p>
					Did you come here after trying to press save on a webpage?{" "}
					<a
						href="javascript:window.history.go(-1)"
						className="button-link-blue"
					>
						Click here to go back and copy the content
					</a>
					. Save it somewhere safe until the wiki is back online. .
				</p>
				<br />
				<Link to="/" className="button-tertiary">
					Return to Homepage
				</Link>
			</div>
		</div>
	)
}

export default Maintenance
