const privacyLevels = {
    "Public": {
        "Read": "Everyone",
        "Edit": "Everyone",
        "Create account": "Everyone",
    },
    "Private A": {
        "Read": "Everyone",
        "Edit": "Everyone",
        "Create account": "Users only",
    },
    "Private B": {
        "Read": "Everyone",
        "Edit": "Users only",
        "Create account": "Everyone",
    },
    "Private C": {
        "Read": "Everyone",
        "Edit": "Users only",
        "Create account": "Users only",
    },
    "Private D": {
        "Read": "Users only",
        "Edit": "Users only",
        "Create account": "Everyone",
    },
    "Private E": {
        "Read": "Users only",
        "Edit": "Users only",
        "Create account": "Users only",
    },
    "Private F + A": {
        "Read": "Everyone",
        "Edit": "Everyone",
        "Create account": "Admin approval required",
    },
    "Private F + C": {
        "Read": "Everyone",
        "Edit": "Users only",
        "Create account": "Admin approval required",
    },
    "Private F + E": {
        "Read": "Users only",
        "Edit": "Users only",
        "Create account": "Admin approval required",
    },
}

export default privacyLevels