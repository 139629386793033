import React, { useState } from "react"
import Helmet from "react-helmet/es/Helmet"
import "./Contact.css"

const Contact = () => {
    const [needsMoreHelp, setNeedsMoreHelp] = useState(false)
    const [isCurrentClient, setIsCurrentClient] = useState(false)
    const [isProspectiveClient, setIsProspectiveClient] = useState(false)

    const handleNeedMoreHelp = () => {
        setNeedsMoreHelp(true)
    }

	return (
		<div className="login-container">
			<Helmet>
				<title>Contact us | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">Contact us</div>
            {!isCurrentClient && !isProspectiveClient && (
                <>
                    <div className="login">
                        <h2>Are you a current or prospective MyWikis client?</h2>
                    </div>
                    <div className="login">
                        <div className="login-half">
                            <div className="login-box-2">
                                <button
                                    className="button-primary loginwiki-btn"
                                    onClick={() => setIsCurrentClient(true)}
                                >
                                    I'm a current MyWikis client
                                </button>
                            </div>
                        </div>
                        <div className="login-half">
                            <div className="login-box-2">
                                <button
                                    className="button-primary loginwiki-btn"
                                    onClick={() => setIsProspectiveClient(true)}
                                >
                                    I'm a prospective MyWikis client
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
			<div className="login">
                {isCurrentClient && (
                    <div>
                    {/* <div className="login-half"> */}
                        <h1>Current MyWikis clients</h1>
                        <p>
                            Thank you for being a loyal MyWikis client! We're here to help you
                            with any questions you may have.
                        </p>
                        <div className="login-box-1">
                            <div className="heading-secondary-dark">Need help?</div>
                            <p>
                                Do you have any questions for us or need support? Our helpful chatbot
                                on the MyWikis Help Center can assist you with many commonly asked
                                questions.
                            </p>
                            <a
                                className="button-primary"
                                href="https://helpcenter.mywikis.wiki/wiki/Special:AskQuestion"
                            >
                                Ask a question to our helpful chatbot
                            </a>
                        </div>
                        <br /><br />
                        <div className="login-box-2">
                            <div className="heading-secondary-dark">
                                Log in to MyWikis{" "}
                                <span className="color-quaternary">Self Service</span>
                            </div>
                            <p>
                                Common changes to your wiki don't require opening a support ticket.{" "}
                                Manage your existing wikis with ease with MyWikis Self Service.
                            </p>
                            <a
                                className="button-green"
                                href="https://selfservice.mywikis.com/"
                            >
                                Log in to MyWikis Self Service
                            </a>
                        </div>
                        <p>
                            <b>Still can't find what you've been looking for?</b>
                        </p>
                        {!needsMoreHelp && (
                            <button
                                className="button-primary loginwiki-btn"
                                onClick={handleNeedMoreHelp}
                            >
                                Yes, I tried the chatbot but didn't find what I needed
                            </button>
                        )}
                        {needsMoreHelp && (
                            <p>
                                If you can't find the answer you're looking for, you can contact us
                                directly{" "}
                                <a href="https://panel.mywikis.com/submitticket.php">
                                    on the MyWikis Client Panel
                                </a>.
                            </p>
                        )}
                        <button
                            className="button-primary loginwiki-btn"
                            onClick={() => {
                                setIsCurrentClient(false)
                                setIsProspectiveClient(true)
                            }}
                        >
                            Oops, I'm actually a prospective client!
                        </button>
                    </div>
                )}
				{/* <div className="login-half"> */}
                {isProspectiveClient && (
                    <div>
                        <h1>Prospective MyWikis clients</h1>
                        <p>
                            We're honored you're considering MyWikis and we'd love to welcome
                            you.
                        </p>
                        <div className="login-box-1">
                            <div className="heading-secondary-dark">Curious about MyWikis?</div>
                            <p>
                                Have general questions about MyWikis? Our helpful chatbot on the
                                MyWikis Help Center can assist you with many commonly asked questions.
                            </p>
                            <a
                                className="button-primary"
                                href="https://helpcenter.mywikis.wiki/wiki/Special:AskQuestion"
                            >
                                Ask a question to our friendly chatbot
                            </a>
                        </div>
                        <br /><br />
                        <div className="login-box-2">
                            <div className="heading-secondary-dark">
                                Got more questions?
                            </div>
                            <p>
                                If you have very specific questions that our helpful chatbot wasn't
                                able to answer, or if you want to discuss your specific situation,
                                you can contact our sales team directly!
                            </p>
                            <a
                                className="button-green"
                                href="https://panel.mywikis.com/contact.php"
                            >
                                I'm a prospective client who wants to talk with the sales team
                            </a>
                        </div>
                        <button
                            className="button-primary loginwiki-btn"
                            onClick={() => {
                                setIsCurrentClient(true)
                                setIsProspectiveClient(false)
                            }}
                        >
                            Oops, I'm actually a current client!
                        </button>
                    </div>
                )}
			</div>
		</div>
	)
}

export default Contact
