import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./ForCompanies.css"
import PlansTable from "../../components/PlansTable/PlansTable"
import CallToAction from "../../components/CallToAction/CallToAction"

const ForCompanies = () => {
	return (
		<div className="for-companies-container">
			<Helmet>
				<title>
					MyWikis for Companies and Organizations | MyWikis | Personalized wiki
					hosting
				</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">
				MyWikis for Companies and Organizations
			</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">
					Corporate and organizational wikis are complicated to run. Let us
					manage your wiki for you.
				</div>
			</div>
			<div className="for-companies">
				<p className="body-text-dark">
					<b>
						Let us take care of the busy work involved with managing a MediaWiki
						wiki. Get back to doing the important work rather than focusing
						efforts on wiki administration. Recover your IT/developer employees'
						productive hours by signing up for a MyWikis wiki.
					</b>{" "}
					<hr className="wiki-info-divider" />
					Our competitors charge hundreds of dollars per month or more. That's
					totally unaffordable! We give you the same quality of support starting
					at just $15/month. Place your wiki on a blazing fast virtual private
					server for just $35/month.
				</p>
			</div>
			<div className="bullet-section">
				<div className="heading-tertiary bullet-heading">
					With over ten years of experience on MediaWiki, we're experts at
					running wikis. You can be sure your wiki is in the right hands and get
					additional features included with your wiki, at no extra cost, such as
					VisualEditor on our Pro and above plans.
				</div>
				<ul className="bullet-list">
					<li className="bullet">
						<b>Expert migration</b>. We offer both guided and end-to-end expert
						migration of your wiki. <Link to="/contact">Contact us</Link>{" "}
						for a personalized quote.
					</li>
					<li className="bullet">
						<b>Unlimited users</b> with each plan
					</li>
					<li className="bullet">
						<b>Easy-to-use VisualEditor</b> means editing your wiki doesn't
						require you to learn code
					</li>
					<li className="bullet">
						<b>Predictable, affordable, transparent pricing</b> every billing
						cycle, so you never spend more than what's advertised
					</li>
					<li className="bullet">
						<b>No ads or MyWikis branding</b> on your wiki
					</li>
					<li className="bullet">
						<b>Many layers of privacy</b> available to secure your wiki and
						content, including IP address based restriction
					</li>
					<li className="bullet">
						<b>SSO integration</b> with many leading providers (such as Okta,
						Azure Active Directory, Amazon Cognito, Ping Identity), and
						protocols, including LDAP and SAML
					</li>
					<li className="bullet">
						<b>World-class built-in spam prevention</b> (exclusive to MyWikis)
						for all wikis
					</li>
					<li className="bullet">
						<b>Extensible wiki software</b> to let you customize to your wiki's
						needs
					</li>
					<li className="bullet">
						<b>Lightning-fast technical support</b> available to help you with
						your needs
					</li>
				</ul>
				<br />
				<p className="are-you-moving">
					Are you moving to MyWikis?{" "}
					<Link className="button-link" to="/movehere">
						Find out how we can help you
					</Link>
					.
				</p>
			</div>
			<div className="plans-container">
				<div className="heading-primary-light center-text">
					Plans for Companies and Organizations
				</div>
				<PlansTable plans={["Business Foundation", "Business Pro", "Business Quantum",
					"Enterprise"]} />
			</div>
			<CallToAction />
		</div>
	)
}

export default ForCompanies
