import React from "react"
import { Link } from "react-router-dom"
import typingBubbleLightIcon from "../../images/typing-bubble-icon-light.svg"
import smallArrowIcon from "../../images/small-arrow-icon.svg"
import "./CallToAction.css"

const CallToAction = () => {
	return (
		<div className="cta-section">
			<div className="cta-box cta-box-1">
				<div className="heading-secondary-dark">
					Want to see the power of our services in action?
				</div>
				<div>
					<Link to="/demo" className="button-primary cta-box-button">
						Try Our Demo!
					</Link>
				</div>
			</div>
			<div className="cta-box cta-box-2">
				<div className="heading-secondary-light">Ready to create a wiki?</div>
				<div>
					<Link to="/createawiki" className="button-primary cta-box-button">
						Join Us Today! &nbsp;&nbsp;&nbsp;
						<img
							src={smallArrowIcon}
							alt="arrow"
							className="light-arrow-icon"
						/>
					</Link>
				</div>
			</div>
			<div className="cta-box cta-box-3">
				<div className="heading-secondary-dark" id="have-questions-text">
					Have any questions?
				</div>
				<div>
					<Link to="/contact" className="button-primary cta-box-button">
						<img
							alt="typing-bubble"
							src={typingBubbleLightIcon}
							className="typing-bubble-icon"
						/>
						Contact Us
					</Link>
				</div>
			</div>
		</div>
	)
}

export default CallToAction
