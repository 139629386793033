import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./MoveHere.css"
import PlansTable from "../../components/PlansTable/PlansTable"
import CallToAction from "../../components/CallToAction/CallToAction"

const MoveHere = () => {
	return (
		<div>
			<Helmet>
				<title>
					Move your wiki to MyWikis today | MyWikis | Personalized wiki hosting
				</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">
				Move your wiki to MyWikis
			</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">
					Running a MediaWiki wiki by yourself? We know how complicated it is to
					run. Let us manage your wiki for you.
				</div>
			</div>
			<div className="move-here">
				<p className="body-text-dark">
					<b>
						Let us take care of the busy work involved with managing a MediaWiki
						wiki. Get back to doing the important work rather than focusing
						efforts on wiki administration. Recover your IT/developer employees'
						productive hours by moving your wiki to MyWikis.
					</b>{" "}
					<br />
					<br />
					With over ten years of experience on MediaWiki, we're experts at
					running wikis. You can be sure your wiki is in the right hands and get
					additional features included with your wiki, at no extra cost, such as
					VisualEditor on our Pro and above plans.
					<br />
					<br />
					Our painless import process is included. We guide you through the
					import process and do as much of it for you as possible. Plus, it's
					available at no additional charge for any plan!
					<hr className="wiki-info-divider" /> Got questions?{" "}
					<Link to="/contact" className="button-link">
						Talk with our sales team
					</Link>
					, who will be able to help answer your questions.
					<br />
					<br />
					Ready to move?{" "}
					<Link id="choose-plan" to="#choose-plan" className="button-link">
						Create a wiki first
					</Link>{" "}
					 and then let our support team know you'd like to move your wiki
					to MyWikis. (Instructions on{" "}
					<a
						href="https://panel.mywikis.com/index.php?rp=/knowledgebase/19/Importing-content-into-your-wiki.html"
						className="button-link"
					>
						importing content into your wiki
					</a>
					)
				</p>
			</div>
			<div className="bullet-section">
				<div className="heading-tertiary bullet-heading">
					With over ten years of experience on MediaWiki, we're experts at
					running wikis. You can be sure your wiki is in the right hands and get
					additional features included with your wiki, at no extra cost, such as
					VisualEditor on our Pro and above plans.
				</div>
				<ul className="bullet-list">
					<li className="bullet">
						<b>Painless import, included</b>. We guide you through the import
						process and do as much of it for you as possible. Plus, it's
						available at no additional charge for any plan!
					</li>
					<li className="bullet">
						<b>Unlimited users</b> with each plan
					</li>
					<li className="bullet">
						<b>Easy-to-use VisualEditor</b> means editing your wiki doesn't
						require you to learn code
					</li>
					<li className="bullet">
						<b>Predictable, affordable, transparent pricing</b> every billing
						cycle, so you never spend more than what's advertised
					</li>
					<li className="bullet">
						<b>No ads or MyWikis branding</b> on your wiki
					</li>
					<li className="bullet">
						<b>Many layers of privacy</b> available to secure your wiki and
						content, including IP address based restriction
					</li>
					<li className="bullet">
						<b>SSO integration</b> with many leading providers (such as Okta,
						Azure Active Directory, Amazon Cognito, Ping Identity), and
						protocols, including LDAP and SAML
					</li>
					<li className="bullet">
						<b>World-class built-in spam prevention</b> (exclusive to MyWikis)
						for all wikis
					</li>
					<li className="bullet">
						<b>Extensible wiki software</b> to let you customize to your wiki's
						needs
					</li>
					<li className="bullet">
						<b>Lightning-fast technical support</b> available to help you with
						your needs
					</li>
				</ul>
				<br />
				<p className="are-you-moving">
					Are you moving to MyWikis?{" "}
					<Link className="button-link" to="/movehere">
						Find out how we can help you
					</Link>
					.
				</p>
			</div>
			<div className="plans-container">
				<div className="heading-primary-light center-text">Choose a Plan</div>
				<PlansTable plans={["Business Foundation", "Business Pro", "Business Quantum",
					"Enterprise"]} />
			</div>
			<CallToAction />
		</div>
	)
}

export default MoveHere
