import React, { useState, useEffect, useRef } from "react"
import Helmet from "react-helmet/es/Helmet"
import "./Privacy.css"
import privacyLevels from "../../utils/privacylevels"

const levels = [
	"Public",
	"Private A",
	"Private B",
	"Private C",
	"Private D",
	"Private E",
	"Private F + A",
	"Private F + C",
	"Private F + E",
]

const Privacy = () => {
	const criteria = ["Read", "Edit", "Create account"]
	const inputReadEveryone = useRef(null)
	const inputEditUsersOnly = useRef(null)
	const [read, setRead] = useState("Everyone")
	const [edit, setEdit] = useState("Everyone")
	const [createAcc, setCreateAcc] = useState("Everyone")
	const [desiredPrivacy, setDesiredPrivacy] = useState("Public")
	useEffect(() => {
		let foundMatch = false

		for (const level of levels) {
			if (
				privacyLevels[level]["Read"] === read &&
				privacyLevels[level]["Edit"] === edit &&
				privacyLevels[level]["Create account"] === createAcc
			) {
				setDesiredPrivacy(level)
				foundMatch = true
			}
		}
		if (!foundMatch)
			setDesiredPrivacy("A privacy level with this criteria does not exist.")
	}, [read, edit, createAcc])

	const handleReadUsersOnlyClick = () => {
		if (edit === "Everyone") {
			inputEditUsersOnly.current.click()
			setEdit("Users only")
		} else {
		}
	}

	const handleEditEveryoneClick = () => {
		if (read === "Users only") {
			inputReadEveryone.current.click()
			setRead("Everyone")
		} else {
		}
	}

	return (
		<div>
			<Helmet>
				<title>Privacy Levels | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">
				Privacy Levels
			</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">
					MyWikis takes privacy seriously.
				</div>
				<p className="subheading-primary-light">
					We offer various privacy settings for your wiki. Choose the one that
					suits your wiki best. You can always change your privacy level later.
				</p>
			</div>
			<div className="what-level body-text-dark">
				<div className="heading-primary-dark center-text">
					What privacy level do I need?
				</div>
				<p className="body-text-dark center-text">
					Use the tool below to find what your desired privacy level is:
				</p>
				<div className="what-level-calculator-container">
					<div className="what-level-calculator level-box">
						<div>
							<div className="privacy-criteria">Read</div>
							<div className="radio-container">
								<input
									className="criteria-radio"
									ref={inputReadEveryone}
									type="radio"
									name="read"
									id="read-everyone"
									value="Everyone"
									defaultChecked={true}
									onChange={(e) => setRead(e.target.value)}
								/>
								<label className="criteria-label" htmlFor="read-everyone">
									Everyone
								</label>

								<input
									className="criteria-radio"
									onClick={handleReadUsersOnlyClick}
									type="radio"
									name="read"
									id="read-usersonly"
									value="Users only"
									onChange={(e) => setRead(e.target.value)}
								/>
								<label className="criteria-label" htmlFor="read-usersonly">
									Users only
								</label>
							</div>
						</div>
						<div className="privacy-criteria">Edit</div>
						<div className="radio-container">
							<input
								className="criteria-radio"
								onClick={handleEditEveryoneClick}
								type="radio"
								name="edit"
								id="edit-everyone"
								value="Everyone"
								defaultChecked={true}
								onChange={(e) => setEdit(e.target.value)}
							/>
							<label className="criteria-label" htmlFor="edit-everyone">
								Everyone
							</label>

							<input
								className="criteria-radio"
								ref={inputEditUsersOnly}
								type="radio"
								name="edit"
								id="edit-usersonly"
								value="Users only"
								onChange={(e) => setEdit(e.target.value)}
							/>
							<label className="criteria-label" htmlFor="edit-usersonly">
								Users only
							</label>
						</div>
						<div className="privacy-criteria">Create account</div>
						<div className="radio-container">
							<input
								className="criteria-radio"
								type="radio"
								name="createacc"
								id="createacc-everyone"
								value="Everyone"
								defaultChecked={true}
								onChange={(e) => setCreateAcc(e.target.value)}
							/>
							<label className="criteria-label" htmlFor="createacc-everyone">
								Everyone
							</label>
							<input
								className="criteria-radio"
								type="radio"
								name="createacc"
								id="createacc-usersonly"
								value="Users only"
								onChange={(e) => setCreateAcc(e.target.value)}
							/>
							<label className="criteria-label" htmlFor="createacc-usersonly">
								Users only
							</label>{" "}
							<input
								className="criteria-radio"
								type="radio"
								name="createacc"
								id="createacc-admin"
								value="Admin approval required"
								onChange={(e) => setCreateAcc(e.target.value)}
							/>
							<label
								className="criteria-label createacc-admin"
								htmlFor="createacc-admin"
							>
								Admin approval required
							</label>
						</div>
					</div>
					<div className="desired-level-container body-text-light">
						<div>
							Your desired privacy level: <br />
						</div>
						<br />
						<b className="desired-level">{desiredPrivacy}</b>
					</div>
				</div>
			</div>
			<div className="privacy-table-container">
				<div className="heading-primary-light center-text">Privacy Levels</div>
				<div className="plans-table-container">
					<div className="plans-table-row privacy-header-row">
						<div className="plans-table-header-cell cell-hide">
							<div className="top-left-cell criteria-column-label">
								Permissions
							</div>
						</div>
						{criteria.map((plan, index) => (
							<div key={index} className="plans-table-header-cell">
								<div className="center-text criteria-column-label">{plan}</div>
							</div>
						))}
					</div>
					{levels.map((feature, ind) => (
						<React.Fragment key={ind}>
							<div className="feature-name">{feature}</div>
							<div className="plans-table-row">
								<div className="plans-table-cell" id="feature-name-inline">
									{feature}
								</div>
								{criteria.map((plan, index) => (
									<div
										key={index}
										className={
											privacyLevels[feature][plan] === "Everyone"
												? "plans-table-cell everyone"
												: privacyLevels[feature][plan] === "Users only"
												? "plans-table-cell usersonly"
												: "plans-table-cell adminapprove"
										}
									>
										<div className="plans-table-cell-title">
											{plan}
											<br />
										</div>{" "}
										<span className="cell-text">
											{privacyLevels[feature][plan]}
										</span>
									</div>
								))}
							</div>
						</React.Fragment>
					))}
				</div>
			</div>
		</div>
	)
}

export default Privacy
