import React from "react"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./PrivacyLaws.css"
import getPrivacyLaws from "../../utils/getprivacylaws"

const PrivacyLaws = () => {
	const { search } = useLocation()
	const searchParams = new URLSearchParams(search)
	const country = searchParams.get("country")

	return (
		<div>
			<Helmet>
				<title>
					Data privacy compliance | MyWikis | Personalized wiki hosting
				</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">
				Data privacy compliance
			</div>
			<div className="privacy-laws body-text-dark">
				{country === null ? (
					<>
						<p>
							MyWikis complies with data privacy laws around the world for all
							jurisdictions in which we do business. We take protecting your
							data very seriously. We pledge to not misuse your data and will
							not share it with third parties except as absolutely necessary for
							the conduct of business. Any data sharing will be kept to a
							minimum and will be listed under our Privacy Policy.
						</p>
						<p>
							To see what data privacy actions are available to you, use the
							following wizard:
						</p>
						<form method="get" action="/privacylaws">
							<div>
								<label>
									<b>Area of residence</b>
								</label>
								<div className="privacy-laws-dropdown-container">
									<select name="country" className="privacy-laws-dropdown">
										<option value="invalid">Select an area</option>
										<option value="Brazil">Brazil</option>
										<option value="EU">European Union</option>
										<option value="UK">United Kingdom</option>
										<option value="US">United States of America</option>
										<option value="other">
											I live in a country that's not in this list
										</option>
									</select>
								</div>
								<button
									className="button-primary privacy-laws-submit"
									type="submit"
								>
									Submit
								</button>
							</div>
						</form>
					</>
				) : (
					<>
						{getPrivacyLaws(country)}
						<br />
						<Link to="/privacylaws" className="button-tertiary go-back-btn">
							Go back
						</Link>
					</>
				)}
			</div>
		</div>
	)
}

export default PrivacyLaws
