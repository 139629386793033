import React from "react"
import TestimonialCard from "../../components/TestimonialCard/TestimonialCard"
import testimonials from "../../utils/testimonials"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import SwiperCore, { Navigation, Autoplay } from "swiper/core"

SwiperCore.use([Autoplay, Navigation])

const TestimonialCarousel = () => {
	return (
		<div className="testimonial-carousel-container">
			<Swiper
				slidesPerView={1}
				spaceBetween={30}
				loop={true}
				allowTouchMove={false}
				autoplay={{
					delay: 5000,
					disableOnInteraction: true,
				}}
				navigation={true}
				className="mySwiper"
			>
				{testimonials.map((testimonial, index) => (
					<SwiperSlide>
						<TestimonialCard
							icon={testimonial.icon}
							text={testimonial.text}
							author={testimonial.author}
							key={index}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}

export default TestimonialCarousel

						
