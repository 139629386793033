import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./Careers.css"

const Careers = () => {
	return (
		<div className="careers-container">
			<Helmet>
				<title>Careers | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">
				Careers
			</div>
			<div className="careers">
				<h3>Who are we looking for?</h3>
				<ul className="careers-bullet-list body-text-dark">
					<li className="careers-bullet">
						Motivated developers with a passion for wikis and collaborative web
						platforms and working proficiency in PHP.
					</li>
					<li className="careers-bullet">
						Passionate, customer-oriented representatives who can learn quickly
						and problem solve.
					</li>
					<li className="careers-bullet">
						Helpful, expert wiki experience specialists who personalize each
						customer's experience and make a deep impact on the customer.
					</li>
					<li className="careers-bullet">
						Creative, innovative, and altruistic publicists who see beyond
						limits, strive to explore the unexplored, and live and breathe our
						ethos.
					</li>
				</ul>
				<h3>Why work for MyWikis?</h3>
				<ul className="careers-bullet-list body-text-dark">
					<li className="careers-bullet">
						<b>Flexible hours!</b> Work whenever you want, wherever you want.
						The faster you work, the more free time you get. You'll get work
						from the comfort of wherever you want to work!
					</li>
					<li className="careers-bullet">
						<b>We're growing!</b> We are currently experiencing a period of
						growth. As part of our stable growth plan, we will need additional
						staff members. Opportunities in our company will be increasing, and
						in the long run, we hope to soon expand at a reasonable, stable
						pace.
					</li>
					<li className="careers-bullet">
						<b>Opportunities for advancement within the company.</b> Since our
						company is quite small, you will have the chance to advance your
						career once our company hires more employees.
					</li>
				</ul>
				<h3>Positions currently available</h3>
				<ul>
					<li className="careers-bullet">
						<Link className="button-link" to="##">
							<b>Customer Sales Representative</b>
						</Link>
					</li>
				</ul>
				<h3>Applying for a job</h3>
				<p>
					Please{" "}
					<a
						className="button-link"
						href="https://support.mywikis.com/tss/open.php"
						target="_blank"
						rel="noreferrer"
					>
						create a ticket here
					</a>{" "}
					to request more information or to initiate your application.
				</p>
				<p>
					Please write your message in English. You may go ahead and attach a
					r&eacute;sum&eacute; or CV (curriculum vit&aelig;). We will offer you
					a job if we feel you are best suited for MyWikis.
				</p>{" "}
				<br />
				<a
					href="//support.mywikis.com/tss/open.php"
					className="button-tertiary"
				>
					Apply Now
				</a>
			</div>
		</div>
	)
}

export default Careers
