import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./VPS.css"
import "../../components/Addons/Addons.css"
import CallToAction from "../../components/CallToAction/CallToAction"
import vpsAddonsInfo from "../../utils/vpsaddons"

const VPS = () => {
    const addonsList = [
		"Monthly Maintenance",
		"Elite Support",
		"Consultations",
		"Project Development",
	]

	return (
		<div>
			<Helmet>
				<title>
					Wiki on Dedicated Servers | MyWikis | Personalized wiki hosting
				</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">
				Wiki on Dedicated Servers
			</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">
					Your wiki, supercharged and customized exactly the way you want it.{" "}
				</div>
				<p className="body-text-light">
					<i>Host your wiki on a managed virtual private server with MyWikis Dedicated.</i>
				</p>
			</div>
			<div className="wiki-info">
				<p className="body-text-dark">
					<b>
						Hosting your wiki on MyWikis Dedicated gives you more control and flexibility,
                        while also enjoying performance benefits from having a dedicated
                        server just for running your wiki.
					</b>{" "}
					<br />
					<br />
					Our Cloud plans are perfect for most use cases.
                    Some wikis may require specialized software configuration that requires
                    operating on a separate platform from our Cloud platform. In these
                    cases, our Dedicated plans offer the perfect balance between price and flexibility,
                    while still providing the expertly-managed features and power that our Cloud
                    platform is known for providing.
					<hr className="wiki-info-divider" />
                    <ul>
                        <li>Customized versions of new and existing extensions</li>
                        <li>Custom skins</li>
                        <li>Custom supporting software can be installed</li>
                        <li>SSH access upon request</li>
                        <li>Dedicated resources used for running your wiki</li>
                    </ul>
                    <hr className="wiki-info-divider" />
                    <p>
                        <b>
                            Tier 1
                        </b>{" "}
                        Our base tier is a solid choice for wikis, providing dedicated compute
                        power to your server with full flexibility for customization. Great for
                        medium traffic wikis or wikis needing a high degree of customization.
                        1 GB of RAM. <b>$79.99/mo</b>
                    </p>
                    <p>
                        <b>
                            Tier 2
                        </b>{" "}
                        Add compute power and memory to your wiki to gain access to caching and
                        increased performance. 2 GB of RAM. <b>$99.99/mo</b>
                    </p>
                    <p>
                        <b>
                            Tier 3
                        </b>{" "}
                        Get enough power to run Elasticsearch. 4 GB of RAM. <b>$119.99/mo</b>
                    </p>
                    <p>
                        <b>
                            Tier 4
                        </b>{" "}
                        Perfect for high-traffic wikis with the need for powerful computation to support
                        thousands of concurrent users. 8 GB of RAM. <b>$149.99/mo</b>
                    </p>
                    <p>Higher tiers are available for wikis requiring more resources or with complex setups. Speak with us for more details.</p>
                    <hr className="wiki-info-divider" />
                    <p>Additional services and features can be added to your VPS:</p>
                    <ul>
                        <li>Varnish frontend caching (Additional <b>$99.99/mo</b>)</li>
                    </ul>
				</p>
			</div>
			<div className="bullet-section">
				<div className="heading-tertiary bullet-heading">
					Enjoy the benefits unique to our Dedicated wikis.
				</div>
				<ul className="bullet-list body-text-dark">
					<li className="bullet">
						<b>
                            SSH access = full control of your wiki.
                        </b>{" "}
                        You can always SSH into the VPS and customize your wiki. You have full
                        control of your wiki, always.
					</li>
					<li className="bullet">
						<b>
                            Performance optimized uniquely for Dedicated.
                        </b>{" "}
						We don't overlook the small things. Performance is difficult to get right,
                        but at MyWikis, your dedicated virtual server is configured to host your website with high
                        performance and high efficiency. We apply special optimizations to your
                        VPS in line with the performance optimizations on our Cloud plans.
					</li>
					<li className="bullet">
						<b>
							Full customization of your wiki.
						</b>{" "}
						There's no limits to how much you can customize your MediaWiki installation
                        on MyWikis Dedicated. The sky is the limit.
					</li>
                    <li className="bullet">
						<b>
							Full access to LocalSettings.php.
						</b>{" "}
						With your SSH credentials, you can edit your wiki's settings file directly.
					</li>
				</ul>
			</div>
            <div className="addons-section">
                <div className="heading-primary-dark">Premium Services</div>
                <div className="heading-secondary-dark">
                    Comprehensive services tailored for your dedicated virtual server.
                </div>
                <div className="addons-container">
                    {addonsList.map((addon, index) => (
                        <div key={index} className="addon-box">
                            <div className="addon-bar" style={{backgroundColor: vpsAddonsInfo[addon]["bar color"]}}>&#8203;</div>
                            <h2 className="heading-secondary">{addon}</h2>
                            <p className="addon-short-description">{vpsAddonsInfo[addon]["addon short description"]}</p>
                            <p className="addon-main-description">{vpsAddonsInfo[addon]["addon main description"]}</p>
                            {
                                /*
                                <div className="addon-pre-price-description">{vpsAddonsInfo[addon]["pre price description"]}</div>
                                <div className="addon-cost">{vpsAddonsInfo[addon]["cost"]}</div>
                                <div className="addon-cost-frequency">{vpsAddonsInfo[addon]["cost frequency"]}</div>
                                <div className="addon-tax-description">{vpsAddonsInfo[addon]["tax description"]}</div>
                                */
                            }
                        </div>
                    ))}
                    <div className="addon-box-full">
                        <div className="addon-bar" style={{backgroundColor: "#72CAFF"}}>&#8203;</div>
                        <h2 className="heading-secondary">The Complete Plan</h2>
                        <p className="addon-short-description">
                            Everything your wiki needs, combined together for you.
                        </p>
                        <p className="addon-main-description">
                            To encapsulate all of the services you'll want to have for your VPS,
                            we've created The Complete Plan, a bundle combining your VPS Hosting
                            with Monthly Maintenance and Elite Support.
                        </p>
                        {/*
                        <div className="addon-pre-price-description">Starting from</div>
                        <div className="addon-cost">$399</div>
                        <div className="addon-cost-frequency">per month</div>
                        <div className="addon-tax-description"></div>
                        */}
                    </div>
                </div>
            </div>
			<div className="help-you">
				<div className="heading-secondary-dark">
					Ready to elevate your wiki hosting?
				</div>
				<div className="help-you-boxes">
                    <Link to="/contact" className="help-you-top-box heading-secondary-light">
                        Speak with sales for more details
                    </Link>
                    <Link to="/vps-signup" className="help-you-bottom-box heading-secondary-light">
                        Sign up for a VPS wiki
                    </Link>
				</div>
			</div>
            <CallToAction />
		</div>
	)
}

export default VPS
