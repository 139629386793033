import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./Default404.css"
import default404Icon from "../../images/default-404.svg"
const Default404 = () => {
	return (
		<div>
			<Helmet>
				<title>Page not found | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">
				Not Found
			</div>
			<div className="default-404">
				<img className="default-404-icon" src={default404Icon} alt="404-icon" />
				<div className="heading-primary-dark">
					Oops! We couldn't find the page or wiki you were looking for.
				</div>{" "}
				<br />
				<p>
					You may have arrived here while trying to access a MyWikis wiki that
					has an expired subscription or is quarantined due to spam. If you need
					help restoring your wiki, please{" "}
					<Link to="/contact" className="button-link-blue">
						contact support
					</Link>
					.<br />
					<br /> For more information on restoring a wiki suspended due to
					missed payments,{" "}
					<a
						href="https://helpcenter.mywikis.wiki/wiki/Restoring_an_unpaid_wiki"
						className="button-link-blue"
					>
						please see this support article
					</a>
					.<br />
					<br /> For more information on releasing a wiki from quarantine due to
					excessive resource usage or another violation of our Terms of Service,{" "}
					<a
						href="https://helpcenter.mywikis.wiki/wiki/Restoring_a_quarantined_wiki"
						className="button-link-blue"
					>
						please see this support article
					</a>
					.
				</p>
				<br />
				<Link to="/" className="button-tertiary">
					Return to Homepage
				</Link>
			</div>
		</div>
	)
}

export default Default404
