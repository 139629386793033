import React from "react"
import "./CompareHostsTable.css"

const CompareHostsTable = () => {
	return (
		<div className="compare-hosts-container">
			<table class="compare-hosts-table">
				<tr>
					<th>Step #</th>
					<th>Wiki creation process at MyWikis</th>
					<th>Wiki creation process at other hosts with automatic setup</th>
					<th>Wiki creation process at other hosts without automatic setup</th>
				</tr>
				<tr>
					<td>1</td>
					<td>Choose a plan</td>
					<td>Choose a plan</td>
					<td>Choose a plan</td>
				</tr>
				<tr>
					<td>2</td>
					<td>Customize your wiki details (1-2 minutes)</td>
					<td>
						Create account credentials and pay for your new plan (2 minutes)
					</td>
					<td>
						Create account credentials and pay for your new plan (2 minutes)
					</td>
				</tr>
				<tr>
					<td>3</td>
					<td>
						Create account credentials and pay for your new plan (2 minutes)
					</td>
					<td>Log in to the hosting panel (1 minute)</td>
					<td>Log in to the hosting panel (1 minute)</td>
				</tr>
				<td>4</td>
				<td>
					Wiki is created automatically with built-in extensions and
					configuration (1-2 minutes)
				</td>
				<td>Create your new site and specify domain name (2 minutes)</td>
				<td>Create your new site and specify domain name (2 minutes)</td>
				<tr>
					<td>5</td>
					<td>
						<span class="glyphicon glyphicon-check"></span> Done! Enjoy your
						wiki!
					</td>
					<td>
						Find the automatic installer and customize your wiki details (2-3
						minutes)
					</td>
					<td>Create MySQL database and get FTP details (2-5 minutes)</td>
				</tr>
				<tr>
					<td>6</td>
					<td>
						Optional: Link your custom domain, or install extensions
						automatically via Self Service
					</td>
					<td>Wiki is created by their script (2-5 minutes)</td>
					<td>Log in to FTP (2 minutes)</td>
				</tr>
				<tr>
					<td>7</td>
					<td></td>
					<td>
						Reconfigure wiki settings, add extensions (10 minutes-5+ hours)
					</td>
					<td>Copy MediaWiki files into directory (2 minutes)</td>
				</tr>
				<tr>
					<td>8</td>
					<td></td>
					<td>Find FTP credentials and log in to FTP (5 minutes)</td>
					<td>Set up MediaWiki using install tool (5-10 minutes)</td>
				</tr>
				<tr>
					<td>9</td>
					<td></td>
					<td>
						<span class="glyphicon glyphicon-check"></span> Done? Enjoy your
						wiki, until the next configuration nightmare...
					</td>
					<td>Upload LocalSettings.php to FTP (2 minutes)</td>
				</tr>
				<tr>
					<td>10</td>
					<td></td>
					<td></td>
					<td>Configure wiki settings, add extensions (10 minutes-5+ hours)</td>
				</tr>
				<tr>
					<td>
						<b>Total time spent</b>
					</td>
					<td>Between 4 to 6 minutes</td>
					<td>Between 24 minutes to 5+ hours</td>
					<td>Between 28 minutes to 5+ hours</td>
				</tr>
			</table>
		</div>
	)
}

export default CompareHostsTable
