import React, { useState } from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import PlansTable from "../../components/PlansTable/PlansTable"
import Services from "../../components/Services/Services"
import Addons from "../../components/Addons/Addons"
import { useTranslation } from "react-i18next"
import "./CreateWiki.css"

const CreateWiki = () => {
	const { t } = useTranslation()
	const [currPlanTable, setCurrPlanTable] = useState(() => <PlansTable />)
	const [activeButton, setActiveButton] = useState("personal-button")

	return (
		<div className="createwiki-container">
			<Helmet>
				<title>Create a wiki | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="white-section heading-primary-light">{t("create-a-wiki.heading.title")}</div>
			<div className="plans-outer-container">
				<div className="plans-container">
					<div className="need-help-container">
						<div className="heading-secondary-light">
							{t("create-a-wiki.heading.get-started-by")}
						</div>
						<p>
							{t("create-a-wiki.heading.need-help")}{" "}
							<Link to="/help-me-choose" className="button-link-white">
								Click here to answer a few questions
							</Link>{" "}
							and we'll give you our recommendation.
						</p>
					</div>
					<button
						onClick={()=>[setCurrPlanTable(<PlansTable plans={["Basics", "Pro", "Quantum", "Ultra"]} />), setActiveButton("personal-button")]}
						className={activeButton === "personal-button" ? "button-quaternary active-btn" : "button-quaternary smaller-btn"}
					>
						Personal Use
					</button>

					<button
						onClick={()=>[setCurrPlanTable(<PlansTable plans={["Business Foundation", "Business Pro", "Business Quantum",
						"Enterprise"]} />), setActiveButton("business-button")]}
						className={activeButton === "business-button" ? "button-quaternary active-btn" : "button-quaternary smaller-btn"}
					>
						Business + Enterprise
					</button>
					{currPlanTable}
				</div>
			</div>
			<div className="addons-section">
				<div className="heading-primary-dark">Add-ons</div>
				<Addons />
			</div>
			<Services />
			<div className="createwiki-info-banner sales-tax-banner">
				<p className="body-text-dark">
					Sales tax not charged in the United States (except for clients from
					Texas at a rate of 6.6%). We collect VAT from EU-based entities who do
					not have a valid VAT number. However, we do not charge VAT in non-EU
					EEA, UK, or any other jurisdictions with VAT, nor do we charge GST in
					Canada, Australia, or any other jurisdictions with GST! We also do not
					charge taxes for any jurisdictions not listed here.
				</p>
			</div>
			<div className="createwiki-info-banner add-wiki-banner">
				<div className="heading-secondary-light">
					Are you a current client adding another wiki?
				</div>
				<p className="body-text-light">
					Please{" "}
					<a
						className="button-link-white"
						href="https://panel.mywikis.com/index.php?rp=/knowledgebase/17/Creating-additional-wikis.html"
					>
						follow the instructions on this knowledgebase article
					</a>{" "}
					to claim your additional wikis free of charge.
				</p>
			</div>
			<div className="createwiki-info-banner discount-banner">
				<div className="heading-secondary-dark discount-heading">
					Want a 5% discount on every bill forever?
				</div>
				<p className="body-text">
					Pay with your U.S.-based bank account and get a 5% discount on every
					single bill.{" "}
					<a
						id="discount-btn"
						href="https://panel.mywikis.com/index.php?rp=/knowledgebase/45/How-to-get-a-5-discount-on-every-payment.html"
					>
						Interested? Click here to learn how to get this discount.
					</a>
				</p>
			</div>
			<div className="come-back">
				<p className="body-text color-quaternary">
					<b>Come back to this page to start making your order.</b>
				</p>
				<p className="body-text-dark">
					Once you finish configuring your wiki and provide your billing
					details, your wiki will be immediately created.
				</p>
			</div>
			<hr className="footer-line" />
		</div>
	)
}

export default CreateWiki
