import React from "react"
import "./TestimonialCard.css"

const TestimonialCard = (props) => {
	return (
		<div className="testimonial-card-container" style={{ transform: `translateX(${props.xPos}%)` }}>
			<p>
				<img className="testimonial-card-icon" src={props.icon} alt="" />
			</p>

			<p className="testimonial-card-text">
				{props.text}
				<b className="testimonial-card-author">{props.author}</b>
			</p>
		</div>
	)
}

export default TestimonialCard
