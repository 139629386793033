import axios from 'axios'

const getGeolocationInfo = async (setLocationInfo) => {
    const geolocationEndpoint1 = 'https://ipapi.co/json/'
    const geolocationEndpoint2 = 'https://ipwho.is/'

    try {
        const response1 = await axios.get(geolocationEndpoint1)
        const locationInfo1 = {
            'country_code': response1.data.country_code,
            'country_name': response1.data.country_name,
            'region_code': response1.data.region_code,
            'in_eu': response1.data.in_eu
        }
        setLocationInfo(locationInfo1)
        console.log(response1.data, locationInfo1)
    } catch (err1) {
        console.log('Endpoint 1 did not work, trying second. Error from endpoint 1:', err1)
        try {
            const response2 = await axios.get(geolocationEndpoint2)
            const locationInfo2 = {
                'country_code': response2.data.country_code,
                'country_name': response2.data.country,
                'region_code': response2.data.region_code,
                'in_eu': response2.data.is_eu
            }
            setLocationInfo(locationInfo2)
            console.log(response2.data, locationInfo2)
        } catch (err2) {
            console.log('Endpoint 2 did not work either. Error from endpoint 2:', err2)
        }
    }
}

export default getGeolocationInfo