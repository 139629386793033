import React from 'react';

const vpsAddonsInfo = {
	"Monthly Maintenance": {
		"bar color": "#72CAFF",
		"addon short description": <p>Expert maintenance of your wiki every month.</p>,
		"addon main description": (
			<p>
				With a VPS, maintaining your wiki doesn't have to be hard. Our MediaWiki experts
                are ready to keep your wiki in tip top shape. We'll keep your wiki, its extensions,
                its skins, and the underlying server operating system up to date for you. We'll
                also monitor your wiki for any issues or areas for improvement and provide expert
                advice to you.
			</p>
		),
        "pre price description": <span>Starting from</span>,
		"cost": <span>$199</span>,
		"cost frequency": <span>per hour per month</span>,
		"tax description": <span>incl. sales tax, excl. VAT</span>,
	},
	"Elite Support": {
		"bar color": "#64CDEC",
		"addon short description": (
			<p>VIP-level personalized technical and MediaWiki support.</p>
		),
		"addon main description": (
			<p>
				Lightning fast, personalized support backed by our Elite Support service level
                agreement. Bypass our support system and contact us via a dedicated email and
                phone number. Call, text, or Zoom with our support team within hours.
			</p>
		),
        "pre price description": <span>Starting from</span>,
		"cost": <span>$199</span>,
		"cost frequency": <span>per month</span>,
		"tax description": <span>incl. sales tax, excl. VAT</span>,
	},
	"Consultations": {
		"bar color": "#004D89",
		"addon short description": <p>Need to speak with our experts?</p>,
		"addon main description": (
            <p>
                Schedule consultation sessions conducted over Zoom. Our experts
                can help you learn how to use MediaWiki, provide advice on
                implementing your vision, and provide other insight.
            </p>
        ),
        "pre price description": <span>Starting from</span>,
		"cost": <span>$60</span>,
		"cost frequency": <span>per hour</span>,
		"tax description": <span>excl. sales tax/VAT</span>,
	},
	"Project Development": {
		"bar color": "#3CBDFF",
		"addon short description": (
			<p>Professional MediaWiki development in-house.</p>
		),
		"addon main description": <p>
            MyWikis develops custom MediaWiki extensions,
            skins, and apps. We tailor to your wiki's needs
            and make sure your wiki works uncompromisingly
            the way you want it. Created by experienced
            software engineers with expertise in MediaWiki.
        </p>,
        "pre price description": <span></span>,
		"cost": <span>Contact us</span>,
		"cost frequency": <span>for more details</span>,
		"tax description": <span>We offer exclusive discounts on project development for VPS plans.</span>,
	},
}

export default vpsAddonsInfo