import React from "react"
import Helmet from "react-helmet/es/Helmet"
import "./Compare.css"
import CallToAction from "../../components/CallToAction/CallToAction"
import CompareHostsTable from "../../components/CompareHostsTable/CompareHostsTable"

const Compare = () => {
	return (
		<div>
			<Helmet>
				<title>Compare | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">
				Compare MyWikis to other wiki hosts
			</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">Why choose MyWikis?</div>
			</div>
			<div className="compare body-text-dark">
				<div className="heading-tertiary">Why MyWikis?</div>
				<ul className="bullet-list">
					<li className="bullet">
						<b>Our wikis work out-of-the-box</b>. You get a fully functional
						wiki in under 5 minutes. You don't need to spend hours configuring
						it; it just works! Start using your wiki immediately.
					</li>
					<li className="bullet">
						<b>We make it easy for anyone to instantly configure their wiki</b>.
						We created MyWikis Self Service in-house to make managing your wikis
						super easy. Instantly configure all aspects of your wiki even if you
						don't know how to code. Exclusive to MyWikis.
					</li>
					<li className="bullet">
						<b>We're a professional wiki host</b>. We are ran by a registered
						company based in the United States. Everyone working on your wiki is
						a hired professional, not a volunteer. We offer SLAs and take
						technical support very seriously.
					</li>
					<li className="bullet">
						<b>We've been doing this for a long time</b>. We've been around for
						a while. We started in February 2012. During our existence, we've
						watched other wiki hosts come and go within a year or two. We are
						very responsible with our finances and careful with how we spend
						money. We're not going anywhere, and you don't have to worry about
						migrating away from us.
					</li>
					<li className="bullet">
						<b>Our prices are the most affordable</b>. Among professional wiki
						hosts, we consistently offer the lowest priced plans. That's a known
						fact that even our competitors know, and they can't beat our
						pricing, nor offer the same amount of features we do.{" "}
						<a
							className="button-link"
							href="https://twitter.com/mywikis/status/1370489627220570122"
						>
							Here's our latest comparison
						</a>
						.
					</li>
					<li className="bullet">
						<b>Our prices are predictable</b>. No surprise bills after you added
						1,000 new users to your wiki. MyWikis doesn't limit how many users
						or pages you have on your wiki, on any of our plans.
					</li>
					<li className="bullet">
						<b>We have secret sauces</b>. We're not just another wiki host. We
						write our own code and invest into the development of our custom
						platform to make your wiki experience the best it can possibly be.
					</li>
					<li className="bullet">
						<b>We make your wiki immediately</b>. Your wiki is created
						automatically, and it only takes 5 minutes maximum from start to
						finish. But don't fear, we're not robots. Our support team adds a
						personal touch to make sure your wiki has everything it needs to
						succeed.
					</li>
					<li className="bullet">
						<b>We make your requests come true</b>. Our resources enable us to
						offer you customization that other wiki hosts can't, especially
						things community-ran wiki hosts can't offer.
					</li>
					<li className="bullet">
						<b>We don't run ads or put our branding on your wiki</b>. We don't
						believe in running ads. Your wiki's webpages remain absent of any
						reference to MyWikis.
					</li>
					<li className="bullet">
						<b>We're built to support privacy by default</b>. While other wiki
						hosts force you to use their centralized user account systems and
						can't offer actual private wikis, our wikis are completely separate
						from each other. In fact, each wiki has different settings and
						different privacy levels. However, we always maintain distance
						between different wikis on our platform.
					</li>
					<li className="bullet">
						<b>We use the best technology there is</b>. We use the fastest
						servers on the market, with Intel Xeon E5 processors running on
						multiple cores, super-fast RAM, and lightning-quick SSD storage. We
						leverage multiple cloud services to scale our services and ensure
						greater reliability. We never sacrifice performance for price.
					</li>
				</ul>
			</div>
			<div className="compare body-text-dark bg-lightblue">
				<div className="heading-tertiary">
					Why choose MyWikis over Miraheze?
				</div>
				<ul className="bullet-list">
					<li className="bullet">
						<b>We've been around much longer</b>. Miraheze was founded in 2015,
						while we were founded in 2012. We began turning a profit in late
						2013, and have been profitable every month since.
					</li>
					<li className="bullet">
						<b>We're run by professionals</b>. Miraheze is ran by unpaid
						volunteers from their community. The community also governs itself,
						creating a complicated set of rules and restrictions. MyWikis is a
						registered company whose focus is to provide you with the best
						quality support, not to make decisions about what you can and can't
						do. Our software engineers have degrees in computer science from the
						top computer science universities in the world. We also pay all of
						our employees a fair market wage.
					</li>
					<li className="bullet">
						<b>We run on consistent revenue streams</b>. While Miraheze has to
						hope every year that donations will cover all of their operating
						expenses, and has very few assets, MyWikis has consistent annual
						revenues&mdash;magnitudes larger than that of Miraheze, a healthy
						balance sheet, and capable of sustaining continued,
						carefully-planned investment in infrastructure improvements and
						professional software development.
					</li>
					<li className="bullet">
						<b>We have more resources than them</b>. Miraheze admits to
						struggles with expanding their infrastructure due to budget
						constraints. For instance, they have publicly stated they can't
						install CirrusSearch due to the cost. These concerns simply don't
						exist at MyWikis, where not only can we pay for our server bills,
						we're able to make investments for the future.
					</li>
					<li className="bullet">
						<b>We have fewer restrictions</b>. Miraheze places a large list of
						restrictions, often hidden to the wiki owner until they do more
						research and realize they can't run their wiki the way they want to.
						At MyWikis, we try to avoid restrictions whenever possible, and
						always try to find you better alternatives or work to make it work
						when we find out something won't work.
					</li>
					<li className="bullet">
						<b>We create your wiki instantly</b>. Miraheze requires wikis to be
						approved before they are created. This means not only do you not get
						immediate access to your wiki, your wiki might also never be
						created, and is at the mercy of community-appointed volunteers.
					</li>
				</ul>
			</div>
			<div className="compare body-text-dark">
				<div className="heading-tertiary">
					Why choose MyWikis over Wiki Valley?
				</div>
				<ul className="bullet-list">
					<li className="bullet">
						<b>We've been around much longer</b>. MyWikis began operations many
						years earlier than Wiki Valley.
					</li>
					<li className="bullet">
						<b>Our pricing doesn't differ by privacy level</b>. A private wiki
						and a public wiki cost the same at MyWikis. For the price of a
						public wiki at Wiki Valley, you can get a MyWikis wiki with many
						more features.
					</li>
					<li className="bullet">
						<b>Our wikis have Self Service capabilities</b>. MyWikis's Self
						Service portal allows you to customize your wiki easily and quickly,
						without having to contact human support.
					</li>
					<li className="bullet">
						<b>We create your wiki instantly</b>. Wiki Valley has to approve
						your wiki subscription before proceeding. Meanwhile, you can get
						your wiki in as soon as 1-2 minutes at MyWikis.
					</li>
				</ul>
			</div>
			<div className="compare body-text-dark bg-lightblue">
				<div className="heading-tertiary">
					Why choose MyWikis over other wiki hosts with prices above $50/mo?
				</div>
				<ul className="bullet-list">
					<li className="bullet">
						<b>Get powerful configuration access for the fraction of the price</b>.
						We created MyWikis Self Service in-house to make managing your wikis
						super easy. Instantly configure all aspects of your wiki even if you
						don't know how to code. Exclusive to MyWikis.
					</li>
					<li className="bullet">
						<b>MyWikis passes on the savings to you</b>. Your wiki will get the
						same resources at a fraction of the cost. We've implemented shared
						optimization across all wikis in order to speed up page load times.
					</li>
					<li className="bullet">
						<b>We don't oversell you</b>. Pay for only what you need. If you
						have fewer than 1,000 daily views and fewer than 5,000 pages, there is
						no reason why you would need a platform that costs {">"}$100/mo.
					</li>
					<li className="bullet">
						<b>We don't nickel-and-dime you</b>. Customizing your wiki shouldn't
						cost anything additional. Get quality support included with your
						subscription at no extra cost.
					</li>
					<li className="bullet">
						<b>We create your wiki instantly</b>. These companies do not create
						your wiki automatically, whereas you'll get your wiki within 5
						minutes at MyWikis.
					</li>
				</ul>
			</div>
			<div className="compare body-text-dark">
				<div className="heading-tertiary">
					Why choose MyWikis over Confluence?
				</div>
				<ul className="bullet-list">
					<li className="bullet">
						<b>We're affordable yet comprehensive</b>. MyWikis plans cost much
						less than Confluence does, but we offer the same features on
						MediaWiki.
					</li>
					<li className="bullet">
						<b>We bill by the month, not by usage</b>. You can have as many uses
						and pages you want on your wiki; we don't charge extra for more
						users or more pages.
					</li>
					<li className="bullet">
						<b>Easier to administer than Confluence</b>. We created MyWikis Self
						Service in-house to make managing your wikis super easy. Instantly
						configure all aspects of your wiki even if you don't know how to
						code. Exclusive to MyWikis.
					</li>
					<li className="bullet">
						<b>We have a nicer WYSIWYG (what you see is what you get) editor</b>
						. The new VisualEditor is faster than ever, with more features than
						previously. It beats out Confluence in terms of ease to use and page
						loading speed.
					</li>
					<li className="bullet">
						<b>We install the best search engines</b>. Rather than using MediaWiki's
						out-of-the-box search, we use an Elasticsearch-based search engine called
						CirrusSearch, which is the gold standard of MediaWiki search engines
						and it's what Wikipedia uses to power its search.
					</li>
					<li className="bullet">
						<b>Our wikis run faster than Confluence</b>. Confluence is an old,
						slow, janky product. The level of optimization MyWikis achieves,
						including the MediaWiki and PHP optimizations made in the past
						several years, have made Confluence slower than MediaWiki on
						average. Stop forcing your employees to wait for the page to load or
						save. MyWikis makes it happen instantly.
					</li>
				</ul>
			</div>
			<div className="compare body-text-dark bg-lightblue">
				<div className="heading-tertiary">
					Why choose MyWikis over other hosts with lower hosting prices?
				</div>
				<ul className="bullet-list">
					<li className="bullet">
						<b>Our specialty is MediaWiki hosting</b>. Other hosts do not
						specialize in MediaWiki, or even wikis at all. In fact, they are
						just regular hosts that sell web space to host your MediaWiki.
					</li>
					<li className="bullet">
						<b>We provide a convenient codeless wiki management tool</b>.
						We created MyWikis Self Service in-house to make managing your wikis
						super easy. Instantly configure all aspects of your wiki even if you
						don't know how to code. Exclusive to MyWikis.
					</li>
					<li className="bullet">
						<b>We have painless setup and configuration</b>. Their "automated"
						setup tools might not be so easy to use, and it's difficult to
						configure your wiki's LocalSettings.php file after it's set up. With
						MyWikis, skip the FTP accounts and the MySQL database configuration.
						Everything just works out of the box.
					</li>
					<li className="bullet">
						<b>We optimize better than they do</b>. We solely focus on MediaWiki
						hosting, so our infrastructure is hyperoptimized for MediaWiki.
						You'll get faster load speeds and better functionality by using
						MyWikis. Their infrastructure is generic and is designed to run
						everybody's workloads, so your MediaWiki instance will likely run
						slower on their hosting than on MyWikis.
					</li>
					<li className="bullet">
						<b>We use higher quality servers than they do</b>. MyWikis uses
						Intel Xeon E5 multicore processors, PCIe RAM, and fast SSD storage
						drives. All the latest technology to make sure your website never
						hangs.
					</li>
					<li className="bullet">
						<b>We are qualified to assist with MediaWiki</b>. We don't just
						provide general hosting support. Our staff have on average 10 years
						of experience in MediaWiki.
					</li>
					<li className="bullet">
						<b>We leverage the cloud for parts of your wiki</b>. In order to
						improve the quality of your wiki using experience, we've moved
						emails and media storage to the cloud in order to improve the
						quality of these services. Best of all, no extra setup needed on
						your side to make this work. And everyone gets generous allowances
						for storage on their plan.
					</li>
					<li className="bullet">
						<b>We have the small details covered</b>. Sometimes, special
						software is needed to run specific parts of MediaWiki. Shared hosts
						might not install it, and they won't let you install anything
						either. We not only install it, we optimize it.
					</li>
					<li className="bullet">
						<b>MyWikis saves you time</b>. Compared to other hosts, MyWikis
						eliminates many hours of lost productivity by doing everything for
						you already. Recover lost hours and get back to doing what you need
						to do.
					</li>
				</ul>
			</div>
            <CompareHostsTable />
			<div className="host-own-wiki">
				<div className="heading-secondary-dark center-text">
					Why don't I just host my own wiki?
				</div>
				<br />
				<p className="body-text-dark">
					You certainly can!{" "}
					<b>
						However, getting a wiki up and running is not as easy as it sounds.
					</b>
					<br />
					<br />
					MediaWiki is an extremely powerful wiki engine (after all, it powers
					Wikipedia!), and thus can be difficult to set up. While the MediaWiki
					set up process is not too difficult, the addition of extensions
					necessary to make the wiki functional can be particularly challenging
					and time consuming. In particular, addition of features necessary for
					many companies, such as single sign on (SSO), data privacy guarantees,
					and visual editing, are the most difficult to configure correctly.
					Running a MediaWiki instance properly requires lots of experience.
					Rather than investing weeks, if not months, on perfecting your wiki
					setup, why not cut it down to a matter of minutes? At MyWikis, we not
					only create your wiki immediately after sign-up, we also bundle all of
					the essential extensions with your wiki. We also provide
					recommendations on how you can improve your wiki, achievable through
					one-click installs of additional extensions.
				</p>
			</div>
			<CallToAction />
		</div>
	)
}

export default Compare
