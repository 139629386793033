import React from "react"
import { Link } from "react-router-dom"
import "./HeroCarousel.css"
import Typewriter from "typewriter-effect"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper/core"
import { Trans, useTranslation } from "react-i18next"

SwiperCore.use([Autoplay, Navigation, Pagination])

const HeroCarousel = () => {
	const { t } = useTranslation()

	return (
		<div className="hero-carousel-container">
			<Swiper
				slidesPerView={1}
				spaceBetween={30}
				loop={true}
				allowTouchMove={false}
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
				}}
				pagination={{
					clickable: true,
				}}
				navigation={true}
				className="mySwiper"
			>
				<SwiperSlide>
					<div className="hero-box">
						<h1 className="heading-primary">
							{t("landing.hero.start-using.heading")}
						</h1>
						<p className="heading-subtext">
							<Trans
								components={{ b: <b /> }}
								i18nKey="landing.hero.start-using.subheading"
							/>
						</p>
						<Link to="/createawiki" className="button-primary hero-button">
							{t("landing.hero.start-using.link1")}
						</Link>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="hero-box">
						<h1 className="heading-primary heading-typing">
							{t("landing.hero.create-wiki-for.headings.prefix")} <br />
							<Typewriter
								options={{
									strings: [
										t("landing.hero.create-wiki-for.headings.suffix1"),
										t("landing.hero.create-wiki-for.headings.suffix2"),
										t("landing.hero.create-wiki-for.headings.suffix3"),
										t("landing.hero.create-wiki-for.headings.suffix4"),
										t("landing.hero.create-wiki-for.headings.suffix5"),
									],
									delay: 40,
									deleteSpeed: 20,
									autoStart: true,
									loop: true,
								}}
							/>
						</h1>
						<p className="heading-subtext">
							{t("landing.hero.create-wiki-for.subheading")}
						</p>
						<Link to="/createawiki" className="button-primary hero-button">
							{t("landing.hero.create-wiki-for.link1")}
						</Link>
						<Link to="/usecases" className="button-secondary hero-button">
							{t("landing.hero.create-wiki-for.link2")}
						</Link>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="hero-box">
						<h1 className="heading-primary">
							{t("landing.hero.top-class.heading")}
						</h1>
						<p className="heading-subtext">
							{t("landing.hero.top-class.subheading")}
						</p>
						<Link to="/plans" className="button-secondary hero-button">
							{t("landing.hero.top-class.link1")}
						</Link>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="hero-box">
						<h1 className="heading-primary">
							{t("landing.hero.visual-editor.heading")}
						</h1>
						<p className="heading-subtext">
							{t("landing.hero.visual-editor.subheading")}
						</p>
						<Link to="/createawiki" className="button-primary hero-button">
							{t("landing.hero.visual-editor.link1")}
						</Link>
						<a
							href="https://www.mediawiki.org/wiki/VisualEditor:Test?veaction=edit"
							className="button-secondary hero-button"
						>
							{t("landing.hero.visual-editor.link2")}
						</a>
			
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="hero-box">
						<h1 className="heading-primary">
							{t("landing.hero.revolutionizing.heading")}
						</h1>
						<p className="heading-subtext">
							{t("landing.hero.revolutionizing.subheading")}
						</p>
						<Link to="/createawiki" className="button-primary hero-button">
							{t("landing.hero.revolutionizing.link1")}
						</Link>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	)
}

export default HeroCarousel
