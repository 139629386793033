import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./Quarantined.css"
import default404Icon from "../../images/default-404.svg"

const Quarantined = () => {
	return (
		<div>
			<Helmet>
				<title>Wiki Quarantined | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">
				Wiki Unavailable
			</div>
			<div className="default-404">
				<img className="default-404-icon" src={default404Icon} alt="404-icon" />
				<div className="heading-primary-dark">
					Oops! This wiki is currently unavailable.
				</div>{" "}
				<br />
				<p>
					The MyWikis wiki you tried to access is quarantined due to spam. If
					you need help restoring your wiki, please{" "}
					<Link to="/contact" className="button-link-blue">
						contact support
					</Link>
					.<br />
					<br /> For more information on getting your wiki back online,{" "}
					<a
						href="https://panel.mywikis.com/index.php?rp=/knowledgebase/31/Restoring-an-unpaid-wiki-subscription.html"
						className="button-link-blue"
					>
						please see this support article
					</a>
					.
				</p>
				<br />
				<Link to="/" className="button-tertiary">
					Return to Homepage
				</Link>
			</div>
		</div>
	)
}

export default Quarantined
