import React from "react"
import Helmet from "react-helmet/es/Helmet"
import "./Extensions.css"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import ExtensionCard from "./ExtensionCard/ExtensionCard"
import { SpinnerWithLogo } from "../../components/WikisSpinner/WikisSpinner"
import FadeIn from "react-fade-in"
import axios from "axios"
import { Link } from "react-router-dom"

const Extensions = () => {
	const extensions_endpoint = "https://selfservicebackend.mywikis.net/extensions/"

	const { t } = useTranslation()

	const [extensionsData, setExtensionsData] = useState({})
	const [hasError, setError] = useState(false)
	const [isLoading, setLoading] = useState(true)

	useEffect(() => {
		axios.get(extensions_endpoint)
			.then((response) => {
				setExtensionsData(response.data.extensions_data)
				setLoading(false)
				setError(false)
			}).catch((error) => {
				setLoading(false)
				setError(true)
			})
	}, [])

	if (isLoading) {
		return (
			<div className="spinner-container">
				<SpinnerWithLogo />
			</div>
		)
    }
 
	return (
		<FadeIn>
			<div className="extensions-container">
				<Helmet>
					<title>Extensions | MyWikis | Personalized wiki hosting</title>
				</Helmet>
				<div className="gradient-section-light heading-primary-light">
					{t('extensions-listing.heading.title')}
				</div>
				<div className="extensions-text-container">
					<p>{t('extensions-listing.intro.para1')}</p>
					<p>
						<Trans i18nKey="extensions-listing.intro.para2">
							a <Link to="/contact">b</Link> c
						</Trans>
					</p>
				</div>
				<div className="extensions">
					{!hasError
						? Object.entries(extensionsData).map(([key, value]) => (
							<ExtensionCard
								name={value.pretty_name}
								description={value.description}
							/>
						))
						: <div className="heading-primary-dark error-message">
							{t('extensions-listing.error')}
						</div>
					}
				</div>
			</div>
		</FadeIn>
	)
}

export default Extensions
