import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./About.css"
import noAdsIcon from "../../images/no-ads-icon.svg"
import freeExtensionsIcon from "../../images/free-extensions-icon.svg"
import accessibleIcon from "../../images/accessible-icon.svg"
import mwPermissionsIcon from "../../images/mw-permissions-icon.svg"
import historyIcon from "../../images/history-icon.svg"

import CallToAction from "../../components/CallToAction/CallToAction"

const About = () => {
	return (
		<div>
			<Helmet>
				<title>About | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">About</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">
					Our experienced staff, with their many years of experience in
					MediaWiki, are here to help your dreams bloom.
				</div>
			</div>
			<div className="philosophy body-text-dark">
				At MyWikis, we host wikis based on your needs and wants. Our philosophy
				is to tailor everything around you and to serve the community. We
				facilitate the creation and maintenance of top-class, premium and custom
				wikis for your needs. Your wiki, personalized your way.
			</div>
			<div className="history body-text-light">
				<div className="left-text started-out">
					We started out on February 22, 2012 with a mission to liberate the
					world from nonchalant wiki hosts by providing a world-class experience
					to our valuable customers. Every day, we help wiki owners with their
					wikis, either creating or modifying their site to perfection.
				</div>
				<br />
				<div className="bottom-history-container">
					<img src={historyIcon} alt="history-icon" className="history-img" />

					<div className="right-text set-apart">
						We set ourselves apart from other wiki hosts by offering our
						services at low, competitive prices while maintaining the high
						quality and reliability that our clients are used to.
					</div>
				</div>
			</div>
			<div className="different-from-competition">
				<div className="heading-primary-dark">
					What are some of the ways we're different from our competition?
				</div>

				<div className="different-body body-text-dark">
					<div className="different-box">
						<div className="different-box-img-container">
							<img
								src={noAdsIcon}
								alt="http-auth-icon"
								className="different-box-img"
							/>
						</div>
						<p>For starters, we don't force ads onto your wikis.</p>
					</div>
					<div className="different-box">
						<div className="different-box-img-container">
							<img
								src={freeExtensionsIcon}
								alt="http-auth-icon"
								className="different-box-img"
							/>
						</div>
						<p>
							We let you customize your wiki with any extension you want,
							because we hate limiting your wiki's potential.
						</p>
					</div>
					<div className="different-box">
						<div className="different-box-img-container">
							<img
								src={accessibleIcon}
								alt="http-auth-icon"
								className="different-box-img"
							/>
						</div>
						<p>
							And best of all, we want to make MyWikis as accessible to you and
							anyone else who uses your wiki.
						</p>
					</div>
					<div className="different-box">
						<div className="different-box-img-container">
							<img
								src={mwPermissionsIcon}
								alt="http-auth-icon"
								className="different-box-img"
							/>
						</div>
						<p>
							Unlike some of our competitors, we understand what having
							boundaries means. That's why we'll help you keep your wiki private
							and secure if you need an internal knowledgebase for your company.
						</p>
					</div>
				</div>
				<br />
				<Link to="/compare" className="button-tertiary">
					See more detailed comparisons
				</Link>
			</div>
			<div className="unlimited body-text-light">
				<p>
					We give our clients the layout and version they want, unlimited users,
					unlimited edit history, unlimited pages, unlimited user groups,
					unlimited bandwidth, unlimited extensions. The unlimited list goes on.
					Join us today.
				</p>
				<Link to="/plans" className="button-quaternary choose-plan-btn">
					Choose a plan
				</Link>
			</div>
			<CallToAction />
		</div>
	)
}

export default About
