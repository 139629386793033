import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./ForPersonal.css"
import PlansTable from "../../components/PlansTable/PlansTable"
import CallToAction from "../../components/CallToAction/CallToAction"

const ForPersonal = () => {
	return (
		<div>
			<Helmet>
				<title>
					MyWikis for Personal Use | MyWikis | Personalized wiki hosting
				</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">
				MyWikis for Personal Use
			</div>
			<div className="statement-banner">
				<div className="heading-secondary-light">
					Setting up your own wiki takes a lot of work. Let us do the heavy
					lifting so you can focus on making your wiki great.
				</div>
			</div>
			<div className="for-personal">
				<p className="body-text-dark">
					<b>
						Let us take care of the busy work involved with managing a MediaWiki
						wiki. Get back to growing your wiki rather than losing time to wiki
						administration.
					</b>{" "}
					<br />
					<br />
					Some wiki services out there might be free, but either run intrusive
					ads, don't give you complete control over your content, don't offer
					professional technical support, or a combination of these! We don't
					run ads, we give you complete control over your wiki, and our support
					team are experts in MediaWiki, available to help you every day. We give
					you quality customer service for an affordable price.
					<hr className="wiki-info-divider" />
					With over ten years of experience on MediaWiki, we're experts at
					running wikis. You can be sure your wiki is in the right hands and get
					additional features included with your wiki, at no extra cost, such as
					VisualEditor on our Pro and above plans.
				</p>
				<br />
				<p className="are-you-moving">
					Are you moving to MyWikis?{" "}
					<Link className="button-link" to="/movehere">
						Find out how we can help you
					</Link>
					.
				</p>
				<br />
			</div>
			<div className="plans-container">
				<div className="heading-primary-light center-text">
					Plans for Personal Use
				</div>
				<PlansTable plans={["Basics", "Pro", "Quantum", "Ultra"]} />
			</div>
			<CallToAction />
		</div>
	)
}

export default ForPersonal
