import React from 'react';

const getPrivacyLaws = (country) => {
	if (country === "invalid") {
		return (
			<>
				<p>Sorry, please go back and select a valid country.</p>
			</>
		)
	} else if (country === "EU" || country === "UK") {
		return (
			<>
				<p>
					Your area: <b>European Union or the United Kingdom</b>
				</p>
				<p>
					As a European Union or British data subject, your data privacy rights
					are guaranteed under the GDPR.
				</p>
				<p>
					Please visit the following form to make your data privacy request:{" "}
					<a className="button-link-blue" href="https://forms.gle/QN17fcY2wsJzWNJ56">GDPR form</a>.
				</p>
			</>
		)
	} else if (country === "Brazil") {
		return (
			<>
				<p>
					Your area: <b>Brazil</b>
				</p>
				<p>
					As a Brazilian data subject, your data privacy rights are guaranteed
					under the LGPD.
				</p>
				<p>
					Please visit the following form to make your data privacy request:{" "}
					<a className="button-link-blue" href="https://forms.gle/QN17fcY2wsJzWNJ56">GDPR and LGPD form</a>.
					(Note: although this form is intended for GDPR compliance, we also use
					it for the LGPD.)
				</p>
			</>
		)
	} else {
		if (country === "US") {
			return (
				<>
					<p>
						Your area: <b>United States of America</b>
					</p>
					<p>
						At this time, MyWikis is not required by the laws of your
						jurisdiction to handle particular data privacy requests. We
						understand your concern for privacy. Rest assured that we never sell
						your data to any third parties and we take protecting your data very
						seriously.
					</p>
					<p>
						MyWikis is currently not bound by the California Consumer Privacy
						Act (CCPA) due to our company size. When we meet the eligibility
						requirements to be bound by the CCPA, we will provide ample notice
						to all clients.
					</p>
				</>
			)
		} else {
			return (
				<>
					<p>
						Your area: <b>Other</b>
					</p>
					<p>
						At this time, MyWikis is not required by the laws of your
						jurisdiction to handle particular data privacy requests. We
						understand your concern for privacy. Rest assured that we never sell
						your data to any third parties and we take protecting your data very
						seriously.
					</p>
				</>
			)
		}
	}
}

export default getPrivacyLaws
