import React, { useState, useEffect } from "react"
import "./App.css"
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom"
import NavBar from "./components/NavBar/NavBar"
import Landing from "./pages/Landing/Landing"
import Features from "./pages/Features/Features"
import Plans from "./pages/Plans/Plans"
import CreateWiki from "./pages/CreateWiki/CreateWiki"
import WhatIsWikiHosting from "./pages/WhatIsWikiHosting/WhatIsWikiHosting"
import ForCompanies from "./pages/ForCompanies/ForCompanies"
import ForPersonal from "./pages/ForPersonal/ForPersonal"
import HelpMeChoose from "./pages/HelpMeChoose/HelpMeChoose"
import MoveHere from "./pages/MoveHere/MoveHere"
import Showcase from "./pages/Showcase/Showcase"
import About from "./pages/About/About"
import Login from "./pages/Login/Login"
import UseCases from "./pages/UseCases/UseCases"
import Compare from "./pages/Compare/Compare"
import PriceCalculator from "./pages/PriceCalculator/PriceCalculator"
import VPS from "./pages/VPS/VPS"
import Consulting from "./pages/Consulting/Consulting"
import Contact from "./pages/Contact/Contact"
import Careers from "./pages/Careers/Careers"
import Extensions from "./pages/Extensions/Extensions"
import Terms from "./pages/Terms/Terms"
import DMCA from "./pages/DMCA/DMCA"
import Privacy from "./pages/Privacy/Privacy"
import PrivacyLaws from "./pages/PrivacyLaws/PrivacyLaws"
import Quarantined from "./pages/Quarantined/Quarantined"
import Suspended from "./pages/Suspended/Suspended"
import Maintenance from "./pages/Maintenance/Maintenance"
import Default404 from "./pages/Default404/Default404"
import Footer from "./components/Footer/Footer"
import i18n from "./i18n"
import { languageOptions } from "./utils/languageoptions"
import getGeolocationInfo from "./utils/geolocation"

const App = () => {
	const [locationInfo, setLocationInfo] = useState({})
	const [currentLanguage, setCurrentLanguage] = useState({
		value: "en",
		label: "English",
	})

	const changeLanguage = (e) => {
		i18n.changeLanguage(e.value)
	}

	useEffect(() => {
		i18n.on('languageChanged', (lng) => {
			for (const option of languageOptions) {
				if (option.value === lng) setCurrentLanguage(option)
			}
		})

		getGeolocationInfo(setLocationInfo)
	}, [])

	return (
		<Router basename="/" forceRefresh={true}>
			<div className="App">
				<NavBar
					currentLanguage={currentLanguage}
					languageOptions={languageOptions}
					changeLanguage={changeLanguage}
					setCurrentLanguage={setCurrentLanguage}
					locationInfo={locationInfo}
				/>
				<Switch>
					<Route exact path="/" component={Landing} />
					<Route path="/index">
						<Redirect to="/" />
					</Route>
					<Route path="/index.html">
						<Redirect to="/" />
					</Route>
					<Route path="/features" component={Features} />
					<Route path="/plans" component={Plans} />
					<Route path="/createawiki" component={CreateWiki} />
					<Route path="/wikicreation_flow">
						<Redirect to="/createawiki" />
					</Route>
					<Route path="/what-is-wiki-hosting" component={WhatIsWikiHosting} />
					<Route path="/for-companies" component={ForCompanies} />
					<Route path="/for-personal" component={ForPersonal} />
					<Route path="/help-me-choose" component={HelpMeChoose} />
					<Route
						path="/demo"
						component={() =>
							window.location.replace(
								"https://helpcenter.mywikis.wiki/wiki/Demo"
							)
						}
					/>
					<Route path="/movehere" component={MoveHere} />
					<Route path="/showcase" component={Showcase} />
					<Route path="/about" component={About} />
					<Route path="/aboutus">
						<Redirect to="/about" />
					</Route>
					<Route path="/login" component={Login} />
					<Route path="/usecases" component={UseCases} />
					<Route path="/compare" component={Compare} />
					<Route
						path="/compare-signature-and-vps-plans"
						component={() =>
							window.location.replace(
								"https://helpcenter.mywikis.wiki/wiki/Signature_versus_VPS"
							)
						}
					/>
					<Route path="/pricecalculator" component={PriceCalculator} />
					<Route path="/consulting" component={Consulting} />
					{/* <Route
						path="/contact"
						component={() =>
							window.location.replace("https://panel.mywikis.com/contact.php")
						}
					/> */}
					<Route path="/contact" component={Contact} />
					<Route path="/vps" component={VPS} />
					<Route
						path="/vps-signup"
						component={() =>
							window.location.replace("https://forms.gle/8mZ4MWoQ8KCuiP457")
						}
					/>
					<Route path="/careers" component={Careers} />
					<Route path="/extensions" component={Extensions} />
					<Route path="/terms" component={Terms} />
					<Route path="/dmca" component={DMCA} />
					<Route path="/privacy" component={Privacy} />
					<Route path="/privacylaws" component={PrivacyLaws} />
					<Route
						path="/news"
						component={() =>
							window.location.replace("https://medium.com/mywikis")
						}
					/>
					<Route
						path="/help"
						component={() =>
							window.location.replace(
								"https://helpcenter.mywikis.wiki/wiki/Main_Page"
							)
						}
					/>
					<Route
						path="/sso-information"
						component={() =>
							window.location.replace(
								"https://helpcenter.mywikis.wiki/wiki/Single_Sign-On"
							)
						}
					/>
					<Route
						path="/migration-information"
						component={() =>
							window.location.replace(
								"https://helpcenter.mywikis.wiki/wiki/Migrate_to_MyWikis"
							)
						}
					/>
					<Route path="/404-quarantined" component={Quarantined} />
					<Route path="/404-suspended" component={Suspended} />
					<Route path="/503-maintenance" component={Maintenance} />
					<Route path="/" component={Default404} />
				</Switch>
				<Footer locationInfo={locationInfo} />
			</div>
		</Router>
	)
}

export default App
