import React from "react"
import { Link } from "react-router-dom"
import "./Services.css"
import servicesIcon from "../../images/services-icon.svg"

const Services = () => {
	return (
		<div className="services-container">
			<div className="heading-secondary-light">Solutions and Consulting Services</div>
			<p className="services-subtext">
				Maximize your wiki's potential. <br /> Make your wiki work for your
				business, not the other way around.
			</p>
			<img
				src={servicesIcon}
				className="services-icon"
				alt="services-icon"
				width="200px"
			/>
			<p className="">
				We'll help you supercharge your wiki and integrate it fully to your
				business needs. <br />
				Dedicated consultants and in-house software engineers help you configure
				your wiki to do what you want, on your terms.{" "}
			</p>
			<br />
			<Link to="/consulting" className="button-quaternary learn-more-btn">
				Learn More
			</Link>
		</div>
	)
}

export default Services
