import "./ExtensionCard.css";
import React from "react";

const ExtensionCard = (props) => {
    return (
        <div className="extension-card">
            <div className="card-details-container">
                <div className="extension-info">
                    <h2>{props.name}</h2>
                    <p>{props.description}</p>
                </div>
            </div>
        </div>
    );
};

export default ExtensionCard;
